<template>
  <PulseLoader
    v-if="loading"
    loading
  />
  <div v-else>
    <form
      v-if="newExoMode"
      class="new-exo"
    >
      <h2>Créer un nouvel exercice</h2>
      <div class="form-field">
        <label>Exercice&nbsp;:</label>
        <input
          v-if="newExoMode"
          v-model="newExoName"
          placeholder="Entrez le nom de l'exercice"
        />
      </div>
      <div class="button-container">
        <button @click.prevent="createExo">Valider</button>
      </div>
    </form>

    <a
      class="blue-link"
      @click="$emit('cancel', )"
    >
      <i class="fa fa-arrow-left" />
      Retourner à la séance
    </a>
    <h2>Ajouter un exercice à la séance</h2>

    <div class="search-bar">
      <input
        class=""
        v-model="searchText"
        type="text"
        placeholder="Rechercher un exercice par son nom"
        @keyup.enter="currentPage=1;searchExo()"
      />
      <i
        class="fa fa-search"
        @click="currentPage=1;searchExo()"
      />
    </div>
    <div
      class="tile-container"
    >
      <div
        class="tile new"
        @click="addNewExo"
      >
        <img
          class="new"
          :src="require('@/assets/new_exo.png',)"
        />
        <div class="name">
          <div>
            Créer un exercice
          </div>
        </div>
      </div>
      <div
        v-for="exo in exercices"
        :key="exo.id"
        class="tile"
        @click="$emit('select', exo, )"
      >
        <img
          v-if="exo.img1"
          :src="exo.img1"
        />
        <img
          v-else
          class="placeholder"
          :src="require('@/assets/exo_placeholder.png',)"
        />
        <div class="name">
          <div>
            {{ exo.nom }}
          </div>
        </div>
      </div>
    </div>
      <Pagination
        v-model="currentPage"
        :total-results="resultsCount"
        @paginate="searchExo"
      />
  </div>
</template>

<script>
import Api from "@/modules/axios";
import Pagination from "@/components/Pagination.vue";

export default {
  name: "ExoChoose",
  components: {
    Pagination,
  },
  data() {
    return {
      exercices: [],
      loading: true,
      searchText: "",
      newExoMode: false,
      newExoName: "",

      currentPage: 1,
      resultsCount: 0,
    };
  },
  mounted() {
    this.searchExo();
  },
  methods: {
    addNewExo() {
      this.newExoMode = true;
      this.newExoName = this.searchText;
    },
    createExo() {
      Api().post(
        "/exercice/",
        { nom: this.newExoName },
      )
        .then((res) => {
          this.$toast("Exercice crée.");
          this.$emit("select", res.data);
        });
    },
    searchExo() {
      this.loading = true;
      Api().get(`/exercice/?search=${this.searchText}&page=${this.currentPage}&page_size=15`)
        .then((res) => {
          this.exercices = res.data.results;
          this.resultsCount = res.data.count;
          this.loading = false;
        });
      return true;
    },
    goBack() {
      this.$router.push(
        {
          name: "seance-details",
          params: {
            id: this.seanceExo.seance,
          },
        },
      );
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/variables.scss" as var;

header.page-seance-exercice-details {
  transition: 0;
  border-radius: 0;
}
</style>

<style lang="scss" scoped>
@use "@/assets/variables.scss" as var;

.input-exercice {
  width: 70%;
  cursor: pointer;
}
.page-seance-exercice-details .img-container {
  height: 16rem;
  overflow: hidden;
  margin: -1.5rem -1.5rem 1rem -1.5rem;
  border-top-right-radius: var.$border-radius-layout;
  position: relative;
  border-top: 2px solid white;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
  }
}

.tile-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;

  .tile {
    position: relative;
    height: 12rem;
    width: 45%;
    overflow: hidden;
    margin: 3px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      overflow: hidden;
      &.placeholder, &.new {
        border: 1px solid #a1a1a1;
        padding: 2rem;
      }
      &.placeholder {
        opacity: 0.3;
      }
    }
    &.new img {
      object-fit: contain;
    }
    .name {
      padding: 5px 5px;
      width: 100%;
      position: absolute;
      bottom: 0;
      > div {
        z-index: 999;
        padding: 5px 2px;
        text-align: center;
        background: var.$transparent-light;
        border-radius: var.$border-radius;
      }
    }
  }
}

.new-exo {
  margin-bottom: 2rem;
  border: 1px solid var.$border-gray;
  padding: 1rem;
  border-radius: var.$border-radius;
  .button-container {
    margin: 2rem 0 1rem 0;
  }
}
</style>
