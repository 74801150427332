<template>
  <div v-if="noConnection">
    <div
      class="img-container"
    >
      <div>
        <h2>Une connexion internet est nécessaire afin de vous inscrire</h2>
        <img :src="require('@/assets/internet.png', )" />
        <a
          class="blue-link"
          @click="reset"
        >
          Réessayer
        </a>
      </div>
    </div>
  </div>
  <form v-else>
    <h2>Inscription</h2>
    <div>
      <label for="nom">Nom&nbsp;:</label>
      <input
        id="nom"
        v-model="data.nom"
        type="text"
      />
    </div>
    <div>
      <label for="prenom">Prénom&nbsp;:</label>
      <input
        id="prenom"
        v-model="data.prenom"
        type="text"
      />
    </div>
    <div>
      <label for="email">Adresse email&nbsp;:</label>
      <em>Utilisée uniquement en cas de mot de passe oublié.</em>
      <input
        id="email"
        v-model="data.email"
        type="email"
      />
    </div>
    <PasswordFields
      @valid-change="handlePasswordValidChange"
    />
    <button
      :disabled="!signupIsPasswordValid || !isEmailValid || !data.nom || !data.prenom"
      @click.prevent="signup"
    >
      Inscription
    </button>
  </form>
</template>

<script>
import PasswordFields from "@/components/login/PasswordFields.vue";
import config from "@/config";
import Api from "@/modules/axios";
import { mapGetters } from "vuex";

export default {
  name: "Signup",
  components: {
    PasswordFields,
  },
  data() {
    return {
      data: {},
      signupIsPasswordValid: false,
      noConnection: false,
    };
  },
  computed: {
    isEmailValid() {
      return this.validateEmail(this.data.email);
    },
    ...mapGetters(["internetStatus"]),
  },
  mounted() {
    this.noConnection = !this.internetStatus;
  },
  methods: {
    reset() {
      this.noConnection = false;
    },
    handlePasswordValidChange({ valid, password }) {
      this.signupIsPasswordValid = valid;
      this.$set(this.data, "password", password);
    },
    validateEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          // eslint-disable-next-line
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    signup() {
      if (!this.signupIsPasswordValid || !this.isEmailValid || !this.data.password) {
        return this.$toast("Une erreur est survenue", true);
      }

      this.$emit("loading", true);
      return Api({ noHeader: true })
        .post(`${config.api}/signup/`, this.data)
        .then((res) => {
          this.$emit("loading", false);
          if (res.data.error) {
            this.$toast(res.data.message, true);
          } else {
            this.$toast("Vous pouvez maintenant vous connecter.");
            this.$emit("signup-complete");
          }
        })
        .catch((err) => {
          if (["ECONNABORTED", "ERR_NETWORK", "ERR_CANCELED"].includes(err.code)) {
            this.noConnection = true;
            this.$emit("loading", false);
          }
        });
    },
  },
};
</script>

<style lang="scss">
.names {
  display: flex;
  gap: 10px;
}
</style>
