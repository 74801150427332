<template>
  <div class="button-container">
    <button
      @click="handleClick"
      class="btn"
      :class="{'secondary': secondary, 'waiting': waitingConfirm, 'outline': outline, }"
      :disabled="disabled"
      v-tooltip="{ content: `Cliquer pour confirmer`, trigger: 'manual', show: waitingConfirm,}"
    >
      {{text}}
      <slot />
    </button>
  </div>
</template>

<script>
export default {
  name: "ValidationButton",
  props: {
    text: String,
    secondary: Boolean,
    outline: Boolean,
    disabled: Boolean,
  },
  data() {
    return {
      waitingConfirm: false,
      timer: 0,
      timerInterval: null,
      defaultDuration: 5000,
    };
  },
  methods: {
    handleClick() {
      if (!this.waitingConfirm) {
        this.waitingConfirm = true;
        this.timer = this.defaultDuration;
        this.timerInterval = setInterval(() => {
          this.timer -= 100;
          if (this.timer <= 0) {
            clearInterval(this.timerInterval);
            this.waitingConfirm = false;
          }
        }, 100);
      } else {
        clearInterval(this.timerInterval);
        this.$emit("validated");
        this.waitingConfirm = false;
      }
    },
  },
};
</script>
