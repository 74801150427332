<template>
  <form>
    <h2>Nouveau mot de passe</h2>
    <PasswordFields
      @valid-change="handlePasswordValidChange"
    />
    <button
      @click.prevent="recover"
      :disabled="!isPasswordValid"
    >
      Valider
    </button>
  </form>
</template>

<script>
import config from "@/config";
import PasswordFields from "@/components/login/PasswordFields.vue";

export default {
  name: "RecoverPassword",
  components: {
    PasswordFields,
  },
  data() {
    return {
      password: "",
      isPasswordValid: false,
      seePassword: false,
    };
  },
  methods: {
    handlePasswordValidChange({ valid, password }) {
      this.isPasswordValid = valid;
      this.password = password;
    },
    recover() {
      if (!this.isPasswordValid || !this.password) {
        return this.$toast("Le mot de passe est trop faible.", true);
      }

      const data = {
        token: this.$route.query.token,
        password: this.password,
      };

      this.$emit("loading", true);
      return fetch(`${config.api}/api/password_reset/confirm/`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          this.$emit("loading", false);
          if (res.status === "OK") {
            this.$router.push("/");
            this.$toast("Vous pouvez maintenant vous connecter.");
          } else if (res.detail === "Pas trouvé.") {
            this.$toast("Token incorrect. Merci de réessayer depuis le lien correct.", true);
          } else {
            this.$toast("Une erreur est survenue", true);
          }
        });
    },
  },
};
</script>

<style>

</style>
