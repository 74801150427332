<template>
  <div class="panel-droite">
    <div>
      <div class="panel-header">
        <h2>{{title}}</h2>
        <i
          class="fa fa-times close"
          @click="$emit('ask-close', )"
        />
      </div>
      <div>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    title: String,
  },
  mounted() {
    this.$emit("open");
  },
  beforeDestroy() {
    this.$emit("close");
  },
};
</script>

<style lang="scss">
@use "@/assets/variables.scss" as var;

.panel-droite {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  padding: 2rem 1rem;
  border-radius: var.$border-radius;
  background-color: var.$transparent-dark;
  transform: none;
  display: flex;
  > div {
    border-radius: var.$border-radius;
    background: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    padding: 1rem;
    min-height: 40%;
    max-height: 100%;
  }
  .panel-header{
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    h2 {
      width: calc(100% - 2rem); // 1rem de margin de chaque côté + 1.5rem de fontsize
      margin: 0;
    }
    .close {
      margin-left: .5rem;
      font-size: 2rem;
    }
  }
}

.panel-droite > div > div:nth-child(2) {
  overflow: auto;
  height: 100%;
}
</style>
