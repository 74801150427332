<template>
  <div>
    <div
      class="img-container"
    >
        <div v-if="empty">
          <h2>Aucun exercice dans cette séance</h2>
          <img :src="require('@/assets/empty.png', )" />
          <a
            class="blue-link"
            @click="$emit('add-exo',)"
          >
            Ajouter un exercice
          </a>
        </div>
        <div v-else>
          <h2>Séance terminée</h2>
          <div
            v-if="!photoSend"
            class="photo-container"
          >
            <p>Profite-en pour te prendre en photo !</p>
            <img :src="require('@/assets/end_photo.png', )" />
            <div class="button-container">
              <label
                for="file-upload"
                class="btn"
              >
                Prendre une photo
              </label>
              <input
                id="file-upload"
                type="file"
                @change="onFileChange"
              />
              <!-- <button
                class="secondary"
                @click="$emit('reset', )"
              >
                Prendre une photo
              </button> -->
            </div>
          </div>
          <div
            v-else
            class="see-photo"
          >
            <img :src="require('@/assets/fin.png', )" />
            <br />
            <a
              :href="photoSend"
              class="blue-link"
              target="_blank"
            >
              <!-- TODO: lien avec token sécurisé + lien vers page de récap des photos -->
              Voir la photo
            </a>
          </div>
          <a
            class="blue-link"
            @click="$emit('add-exo',)"
          >
            Ajouter un exercice
          </a>
          <br />
          <a
            class="blue-link"
            @click="$emit('serie-back',)"
          >
            Revenir à la série précédente
          </a>
        </div>
    </div>
    <div class="button-container">
      <button @click="$emit('reset', )">Retour</button>
    </div>
  </div>
</template>

<script>
import Api from "@/modules/axios";

export default {
  name: "EndEntrainement",
  props: {
    empty: Boolean,
    seance: Object,
  },
  data() {
    return {
      photoSend: false,
    };
  },
  mounted() {
    if (!this.empty) {
      this.$store.dispatch("faceExpression", "look-up-left");
      this.$store.dispatch("faceExpression", "happy");
      this.$store.commit("setAvatarText", `
      Les photos permettent de suivre ton évolution de manière plus visuelle que les chiffres de ta balance !
      `);
    }
  },
  methods: {
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }

      const data = new FormData();
      data.append("img", files[0]);
      if (this.seance && this.seance.id) {
        data.append("seance", this.seance.id);
      }

      Api().post("/photo/", data)
        .then((res) => {
          this.photoSend = res.data.img;
          this.$toast("Photo enregistrée");
        });
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/variables.scss" as var;

.photo-container {
  border: 1px solid var.$border-gray;
  border-radius: var.$border-radius;
  padding: 1rem 1rem 0 1rem;
  margin-bottom: 2rem;
  .button-container {
    margin: 0 0 1rem 0;
  }
  #file-upload {
    display: none;
  }
}

.see-photo {
  margin-bottom: 4rem;
}
</style>
