<template>
  <div>
    <label>Mot de passe&nbsp;:</label>
    <ul>
      <li
        v-for="cond in conditions"
        :key="cond[0]"
      >
        <i
          class="fa"
          :class="cond[1] ?'fa-check' : 'fa-times'"
        />
        {{cond[0]}}
      </li>
    </ul>
    <div class="password-container">
      <input
        id="new_confirm_password"
        v-model="password"
        :type="seePassword ? 'text' : 'password'"
      />
      <i
        class="fa fa-eye"
        @click="seePassword = !seePassword"
      />
    </div>
    <label>Confirmer le mot de passe&nbsp;:</label>
    <ul>
      <li>
        <i
          class="fa"
          :class="this.validPasswordConfirm ?'fa-check' : 'fa-times'"
        />
        Mots de passe identiques
      </li>
    </ul>
    <div class="password-container">
      <input
        id="new_confirm_password"
        v-model="confirmPassword"
        :type="seeConfirmPassword ? 'text' : 'password'"
      />
      <i
        class="fa fa-eye"
        @click="seeConfirmPassword = !seeConfirmPassword"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "PasswordFields",
  data() {
    return {
      password: "",
      seePassword: false,
      confirmPassword: "",
      seeConfirmPassword: false,
    };
  },
  computed: {
    conditions() {
      return [
        ["Au moins 8 caractères", this.validPasswordLength],
        ["Au moins 1 majuscule", this.validPasswordMaj],
        ["Au moins 1 minuscule", this.validPasswordMin],
        ["Au moins 1 chiffre", this.validPasswordNum],
      ];
    },
    validPasswordLength() {
      return this.password.length >= 8;
    },
    validPasswordMaj() {
      return this.regexMatchCount("[A-Z]", this.password) > 0;
    },
    validPasswordMin() {
      return this.regexMatchCount("[a-z]", this.password) > 0;
    },
    validPasswordNum() {
      return this.regexMatchCount("\\d", this.password) > 0;
    },
    validPasswordConfirm() {
      return this.password && this.password === this.confirmPassword;
    },
    validPassword() {
      return (
        this.validPasswordLength
        && this.validPasswordMaj
        && this.validPasswordMin
        && this.validPasswordNum
        && this.validPasswordConfirm
      );
    },
  },
  watch: {
    validPassword() {
      const data = {
        valid: this.validPassword,
        password: this.password,
      };
      this.$emit("valid-change", data);
    },
  },
  methods: {
    regexMatchCount(regex, str) {
      return ((str || "").match(regex) || []).length;
    },
  },
};
</script>

<style>

</style>
