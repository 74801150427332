import Vue from "vue";
import VueRouter from "vue-router";
import Entrainement from "@/components/Entrainement.vue";

import config from "@/config";
import Profile from "@/components/Profile.vue";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "accueil",
    component: () => import(/* webpackChunkName: "accueil" */ "@/components/Accueil2.vue"),
  },
  {
    path: "/beta",
    name: "beta",
    component: () => import(/* webpackChunkName: "accueil" */ "@/components/BetaSubscription.vue"),
  },
  {
    path: "/feedback",
    name: "feedback",
    component: () => import(/* webpackChunkName: "accueil" */ "@/components/Feedback.vue"),
  },
  {
    path: "/admin",
    name: "admin",
    beforeEnter() {
      window.location.href = config.api.replace("api", "admin");
    },
  },
  // {
  //   path: "/radio",
  //   name: "radio",
  //   component: () => import(/* webpackChunkName: "radio" */ "@/components/Radio.vue"),
  // },
  {
    path: "/radio",
    name: "radio",
    component: () => import(/* webpackChunkName: "radio" */ "@/components/RadioSpotify.vue"),
  },
  // {
  //   path: "/radio/petite",
  //   name: "radio-petite",
  //   component: () => import(/* webpackChunkName: "radio" */ "@/components/RadioYtb.vue"),
  // },
  // {
  //   path: "/player",
  //   name: "player",
  //   component: () => import(/* webpackChunkName: "radio-player" */ "@/components/Player.vue"),
  // },
  // {
  //   path: "/player/petite",
  //   name: "player-petite",
  //   component: () => import(/* webpackChunkName: "radio-player" */ "@/components/PlayerYtb.vue"),
  // },
  // {
  //   path: "/player-control/petite",
  //   name: "player-control-petite",
  //   component: () => import(/* webpackChunkName: "radio-player" */ "@/components/PlayerControlYtb.vue"),
  // },
  {
    path: "/player-control",
    name: "player-control",
    component: () => import(/* webpackChunkName: "radio-player" */ "@/components/PlayerControlSpotify.vue"),
  },
  {
    path: "/entrainement",
    name: "entrainement",
    component: Entrainement,
  },
  {
    path: "/seances",
    name: "seances",
    component: () => import(/* webpackChunkName: "seance" */ "@/components/seance/Seances.vue"),
  },
  {
    path: "/seances/:id",
    name: "seance-details",
    component: () => import(/* webpackChunkName: "seance" */ "@/components/seance/SeanceDetails.vue"),
  },
  {
    path: "/seances/:id/qr",
    name: "seance-qr",
    component: () => import(/* webpackChunkName: "seance" */ "@/components/seance/SeanceQrCode.vue"),
  },
  {
    path: "/seances/:id/suivi",
    name: "seance-suivi",
    component: () => import(/* webpackChunkName: "seance" */ "@/components/seance/SeanceSuivi.vue"),
  },
  {
    path: "/exercices",
    name: "exercices",
    component: () => import(/* webpackChunkName: "exercice" */ "@/components/Exercices.vue"),
  },
  {
    path: "/exercice/:id",
    name: "exercice-details",
    component: () => import(/* webpackChunkName: "exercice" */ "@/components/ExerciceDetails.vue"),
  },
  {
    path: "/exercice/:id/stats",
    name: "exercice-stats",
    component: () => import(/* webpackChunkName: "exercice" */ "@/components/ExerciceStats.vue"),
  },
  {
    path: "/calendar",
    name: "calendar",
    component: () => import(/* webpackChunkName: "suivi" */ "@/components/Calendar.vue"),
  },
  {
    path: "/suivi",
    name: "suivi",
    component: () => import(/* webpackChunkName: "suivi" */ "@/components/Suivi.vue"),
  },
  {
    path: "/shared-suivi/:id",
    name: "shared-suivi",
    component: () => import(/* webpackChunkName: "suivi" */ "@/components/Suivi.vue"),
  },
  {
    path: "/suivi-share",
    name: "suivi-share",
    component: () => import(/* webpackChunkName: "suivi" */ "@/components/ShareSuivi.vue"),
  },
  {
    path: "/photos",
    name: "photos",
    component: () => import(/* webpackChunkName: "suivi" */ "@/components/Photos.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
  },
  {
    path: "/install",
    name: "install",
    component: () => import(/* webpackChunkName: "suivi" */ "@/components/Install.vue"),
  },
  {
    path: "/pricing",
    name: "pricing",
    component: () => import(/* webpackChunkName: "suivi" */ "@/components/Price.vue"),
  },
  {
    path: "/pesee",
    name: "pesee",
    component: () => import(/* webpackChunkName: "suivi" */ "@/components/Pesee.vue"),
  },
  {
    path: "*",
    name: "404",
    component: () => import(/* webpackChunkName: "accueil" */ "@/components/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  routes,

});

router.afterEach(() => {
  const avatar = store.getters.avatarInstance;
  if (avatar) {
    setTimeout(() => avatar.viewer.resize(), 50);
  }
});

export default router;
