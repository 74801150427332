<template>
  <div v-if="noConnection">
    <div
      class="img-container"
    >
      <div>
        <h2>Une connexion internet est nécessaire afin de récupérer votre mot de passe</h2>
        <img :src="require('@/assets/internet.png', )" />
        <a
          class="blue-link"
          @click="reset"
        >
          Réessayer
        </a>
      </div>
    </div>
  </div>
  <form v-else>
    <h2>Mot de passe oublié</h2>
    <label>Adresse email&nbsp;:</label>
    <input v-model="email" />
    <button @click.prevent="forgot">Valider</button>
  </form>
</template>

<script>
import config from "@/config";
import Api from "@/modules/axios";
import { mapGetters } from "vuex";

export default {
  name: "ForgotPassword",
  data() {
    return {
      email: "",
      noConnection: false,
    };
  },
  computed: {
    ...mapGetters(["internetStatus"]),
  },
  mounted() {
    this.noConnection = !this.internetStatus;
  },
  methods: {
    reset() {
      this.noConnection = false;
    },
    forgot() {
      if (!this.email) {
        return this.$toast("Veuillez saisir une adresse email", true);
      }

      this.$emit("loading", true);
      return Api({ noHeader: true })
        .post(`${config.api}/password_reset/`, { email: this.email })
        .then((res) => {
          this.$emit("loading", false);
          if (res.data.status === "OK") {
            this.$toast("Un courriel de récupération vous a été envoyé.");
          } else if (
            res.data.email && res.data.email[0]
              && res.data.email[0].indexOf("no active user associated") > 0
          ) {
            this.$toast("Aucun compte n'est lié à cette adresse.", true);
          } else {
            this.$toast(res.data.email[0], true);
          }
        })
        .catch((err) => {
          if (["ECONNABORTED", "ERR_NETWORK", "ERR_CANCELED"].includes(err.code)) {
            this.noConnection = true;
            this.$emit("loading", false);
          } else if (err.response && err.response.data) {
            if (
              err.response.data.email[0].indexOf("find an account associated with that email") > -1
            ) {
              this.$toast(
                "Cette adresse courriel ne semble pas être associée à un compte.", true,
              );
            } else {
              const text = Object.values(err.response.data)[0][0];
              this.$store.dispatch("displayToast", { text, isError: true });
            }
          } else {
            this.$toast("Veuillez saisir un courriel valide", true);
            this.$emit("loading", false);
          }
        });
    },
  },
};
</script>

<style>

</style>
