<template>
  <ExoChoose
    class="exo-choose"
    v-if="!selectedAddExo"
    @cancel="cancel"
    @select="selectExo"
  />
  <div
    v-else
    id="switch_add_exo"
  >
    <div id="add_exo">
      <h2>Ajouter un exercice dans la séance</h2>
      <div>
        <div class="form-field">
          <label>Exercice&nbsp;:</label>
          <span
            class="field"
            @click="selectedAddExo = null"
          >
            {{  selectedAddExo.nom }}
          </span>
        </div>
        <div class="form-field">
          <label>Nombre de série&nbsp;:</label>
          <input
            v-model="addNbSerie"
            type="number"
          />
        </div>
        <div class="form-field">
          <label>Durée du repos&nbsp;:</label>
          <input
            v-model="addRepos"
            type="number"
          />
        </div>
        <div class="button-container">
          <button @click="add">Ajouter</button>
        </div>
        <div class="button-container">
          <button
            class="btn secondary"
            @click="cancel"
          >
            Annuler et revenir à la séance
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ExoChoose from "@/components/ExoChoose.vue";

export default {
  name: "AddExoEntrainement",
  props: {
    currentStep: Object,
  },
  components: {
    ExoChoose,
  },
  data() {
    return {
      selectedSwitchExo: null,
      selectedAddExo: null,
      addNbSerie: 1,
      addRepos: 90,
      loading: true,
    };
  },
  methods: {
    add() {
      this.$emit("add-exo", {
        exo: this.selectedAddExo,
        nbSerie: parseInt(this.addNbSerie, 10),
        repos: this.addRepos,
      });
    },
    cancel() {
      this.$emit("cancel");
    },
    selectExo(exo) {
      this.selectedAddExo = exo;
    },
  },
};
</script>

<style lang="scss">
#switch_add_exo {
  hr {
    margin: 2rem 0;
  }
}
.exo-choose .input.search {
  width: 100%;
}
</style>
