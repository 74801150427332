export default {
  state: {
    allMorphs: [
      "eyeWideLeft", "eyeWideRight", "eyeLookUpLeft", "eyeLookUpRight", "eyeLookInRight",
      "eyeLookOutLeft", "eyeLookInLeft", "eyeLookOutRight", "eyeLookDownLeft", "eyeLookDownRight",
      "mouthSmile", "mouthOpen", "viseme_I", "mouthFrownLeft", "mouthFrownRight", "viseme_O",
      "eyesClosed", "mouthRollLower", "eyeSquintRight", "browDownLeft", "browInnerUp", "browOuterUpRight",
    ],
    allBones: [
      {
        code: "neck-y",
        boneName: "Neck",
        axe: "y",
        defaultValue: 0,
      },
      {
        code: "neck-x",
        boneName: "Neck",
        axe: "x",
        defaultValue: 0.4,
      },
    ],
    avatarInstance: null,
    currentExpression: null,
    avatarShow: false,
    avatarReady: false,
    intervalWait: null,
    intervalBlink: null,
    avatarText: null,
  },
  getters: {
    currentExpression(state) {
      return state.currentExpression;
    },
    avatarShow(state) {
      return state.avatarShow;
    },
    avatarReady(state) {
      return state.avatarReady;
    },
    avatarText(state) {
      return state.avatarText;
    },
    avatarInstance(state) {
      return state.avatarInstance;
    },
  },
  mutations: {
    setCurrentExpression(state, val) {
      state.currentExpression = val;
    },
    setAvatarShow(state, val) {
      state.avatarShow = val;
    },
    setAvatarReady(state, val) {
      state.avatarReady = val;
    },
    setAvatarText(state, val) {
      state.avatarText = val;
    },
    setAvatarInstance(state, val) {
      state.avatarInstance = val;
    },
  },
  actions: {
    resetBeforeAction(store, exclude = []) {
      const morphsToReset = store.state.allMorphs.filter((m) => !exclude.includes(m));
      window.animFace(morphsToReset, 0);

      const bonesToReset = store.state.allBones.filter((m) => !exclude.includes(m.code));
      bonesToReset.forEach((b) => {
        window.animBone(b.boneName, b.axe, b.defaultValue);
      });
    },
    enableAvatar(store) {
      store.commit("setAvatarReady", true);

      function getRandom(min, max) {
        return Math.random() * (max - min) + min;
      }

      function blink() {
        clearInterval(store.state.intervalBlink);
        store.dispatch("faceExpression", "blink");
        store.state.intervalBlink = setInterval(blink, getRandom(4000, 6000));
      }

      store.state.intervalBlink = setInterval(blink, getRandom(4000, 6000));
      // Lorsque l'onglet du site est actif: On active le clignement
      // (qui se fait toutes les xx secondes)
      window.onfocus = () => {
        blink();
      };
      // Lorsque l'onglet n'est plus actif (l'utilisateur a changé d'onglet dans son navigateur),
      window.onblur = () => {
        clearInterval(store.state.intervalBlink);
      };
    },
    faceExpression(store, exp) {
      if (!store.getters.avatarReady || !window.morphs) {
        clearInterval(store.state.intervalWait);
        store.state.intervalWait = setInterval(() => {
          store.dispatch("faceExpression", exp);
        }, 100);
        return true;
      }
      clearInterval(store.state.intervalWait);

      if (exp !== "blink") {
        store.commit("setAvatarShow", true);
        store.commit("setCurrentExpression", exp);
      }

      switch (exp) {
        case "reset":
          store.dispatch("resetBeforeAction", []);
          break;
        case "very-happy":
          store.dispatch("resetBeforeAction", ["viseme_I", "eyesClosed"]);
          window.animFace(["eyesClosed"], 0.2, 25);
          window.animFace(["viseme_I"], 0.8, 25, 0.05);
          break;
        case "happy":
          store.dispatch("resetBeforeAction", ["mouthSmile", "eyesClosed"]);
          window.animFace(["eyesClosed"], 0.1, 25);
          window.animFace(["mouthSmile"], 0.3, 25);
          break;
        case "thinking":
          store.dispatch("resetBeforeAction", [
            "eyeLookOutRight", "eyeSquintRight", "browDownLeft",
            "eyeLookUpRight", "browInnerUp", "eyeLookInLeft", "eyeLookUpLeft", "browOuterUpRight",
          ]);
          window.animFace(["eyeLookOutRight", "eyeSquintRight", "browDownLeft"], 1, 25);
          window.animFace(["eyeLookUpRight", "browInnerUp"], 0.4, 25);
          window.animFace(["eyeLookInLeft", "eyeLookUpLeft"], 0.5, 25);
          window.animFace(["browOuterUpRight"], 0.6, 25);
          break;
        case "sad":
          store.dispatch("resetBeforeAction", [
            "mouthFrownLeft", "mouthFrownRight", "mouthOpen", "eyeLookDownLeft", "eyeLookDownRight",
            "eyesClosed", "mouthRollLower", "neck-x",
          ]);
          window.animFace(["mouthFrownLeft", "mouthFrownRight", "mouthRollLower"], 0.8, 25);
          window.animFace(["mouthOpen", "eyesClosed"], 0.3, 25);
          window.animFace(["eyeLookDownLeft", "eyeLookDownRight"], 0.5, 25);
          window.animBone("Neck", "x", 0.5);
          break;
        case "surprised":
          store.dispatch("resetBeforeAction", ["eyeWideLeft", "eyeWideRight", "viseme_O"]);
          window.animFace(["eyeWideLeft", "eyeWideRight", "viseme_O"], 0.8, 25);
          break;
        case "look-up-left":
          store.dispatch("resetBeforeAction", ["eyeLookUpLeft", "eyeLookUpRight", "neck-y", "neck-x"]);
          window.animFace(["eyeLookUpLeft", "eyeLookUpRight"], 0.5, 25);
          window.animBone("Neck", "y", -0.5);
          window.animBone("Neck", "x", 0.1);
          break;
        case "look-up-right":
          store.dispatch("resetBeforeAction", ["eyeLookUpLeft", "eyeLookUpRight", "neck-y", "neck-x"]);
          window.animFace(["eyeLookUpLeft", "eyeLookUpRight"], 0.5, 25);
          window.animBone("Neck", "y", 0.5);
          window.animBone("Neck", "x", 0.1);
          break;
        case "look-left":
          store.dispatch("resetBeforeAction", ["eyeLookOutRight", "eyeLookInLeft", "neck-y"]);
          window.animFace(["eyeLookOutRight", "eyeLookInLeft"], 1, 25);
          window.animBone("Neck", "y", -0.5);
          break;
        case "look-right":
          store.dispatch("resetBeforeAction", ["eyeLookInRight", "eyeLookOutLeft", "neck-y"]);
          window.animFace(["eyeLookInRight", "eyeLookOutLeft"], 1, 25);
          window.animBone("Neck", "y", 0.5);
          break;
        case "blink":
          // eslint-disable-next-line
          let currentVal = window.morphs.eyesClosed[0].getValue();
          if (currentVal === 1) {
            currentVal = 0;
          }
          window.animFace(["eyesClosed"], 1, 1);
          setTimeout(() => {
            window.animFace(["eyesClosed"], currentVal, 1);
          }, 100);
          break;
        default:
          break;
      }
      return true;
    },
  },
};
