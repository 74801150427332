<template>
  <div
    v-if="!pageLoading"
    id="content"
    :class="{
      'not-logged': !logged,
      'smart-watch-content': isSmartWatch,
      [`content-page-${$route.name}`] : logged,
    }"
  >
    <header :class="logged ? `page-${$route.name}` : ''">
      <div class="header-top">
        <transition name="fade">
          <button
            v-if="logged"
            id="menu_toggle"
            @click="toggleMenu"
          >
            <i class="fa fa-bars" />
          </button>
        </transition>
        <h1>
          <a
            v-show="updateExists"
            @click="refreshApp"
          >
            <img
              :src="require('@/assets/logo_large.png', )"
              v-tooltip="{
                content: tooltipContentUpdate,
                show: updateExists,
                trigger: 'manual',
                classes: ['center-tooltip', 'tooltip-update', ],
              }"
            />
          </a>
          <router-link
            v-if="!updateExists"
            :to="{name:'accueil', }"
          >
            <img :src="require('@/assets/logo_large.png', )" />
          </router-link>
        </h1>
      </div>
      <div
        v-if="toastDisplayed"
        class="toast"
        :class="{
          displayed: toastDisplayed,
          error: toastIsError,
        }"
        @click="toastDisplayed = false"
        v-html="toastText"
      >
        <!-- {{toastText}} -->
      </div>
    </header>
    <transition :name="!menuOpened? 'slideback' : 'slide'">
      <nav
        v-if="menuOpened && logged"
        v-click-outside="toggleMenu"
      >
        <button class="close">
          <i
            class="fa fa-times"
            @click="toggleMenu"
          />
        </button>
        <button
          class="debug"
          @click="debug = !debug"
        />
        <div class="menu-top">
          <router-link
            :to="{name: 'profile', }"
            class="profil-link"
          >
            <figure>
              <img
                v-if="user.avatar"
                :src="user.avatar"
              />
              <i
                v-else
                class="fa fa-user"
                id="profile_link"
              />
            </figure>
            <label>{{user.first_name}} {{user.last_name}}</label>
            <a class="sub">Mes paramètres</a>
          </router-link>
          <ul>
            <li id="feedback_button">
              <router-link :to="{ name: 'feedback', }">
                <button>
                  Aide-nous à<br/>améliorer l'application
                </button>
              </router-link>
            </li>
            <li
              v-for="item in menu"
              :key="item.title + item.url.name"
              class="item-menu"
              :class="{
                'sub': item.sub,
                'current': $route.name === item.url.name && !item.double,
                [item.url.name]: true,
              }"
            >
            <router-link :to="item.url">
              <span>
                <i :class="`fa fa-${item.icon}`" />
                {{item.title}}
              </span>
            </router-link>
            </li>
            <li
              v-if="user.beta_accepted"
              class="item-menu"
            >
              <a @click="startTuto">
                <span>
                  <i class="fa fa-circle-question" />
                  Tutoriel
                </span>
              </a>
            </li>
          </ul>
        </div>
        <div class="menu-bottom">
          <div
            class="nav-download-app"
            v-if="!isInstalled"
          >
            <span @click="$router.push({ name: 'install', }, )">
              <i class="fa fa-download"/>
              Installer l'application
            </span>
          </div>
          <div
            class="nav-logout"
            @click="logout"
          >
            <span>
              <i class="fa fa-right-from-bracket"/>
              Déconnexion
            </span>
          </div>
        </div>
      </nav>
    </transition>
    <!-- ================================================ -->
    <main
      :class="{
        [`page-${$route.name}`] : logged,
        'smart-watch-main': isSmartWatch,
        'with-avatar': avatarShow,
      }"
    >
      <div
        class="loader-container"
      >
        <PulseLoader
          :loading="authLoading"
        />
      </div>
      <router-view
        v-if="logged"
        class="router-view"
      />
      <!-- ======================== RECUPERATION DE MOT DE PASSE ======================== -->
      <div
        v-else
        class="auth-form"
      >
        <template v-if="$route.fullPath.startsWith('/recover',)">
          <RecoverPassword @loading="authLoading = $event"/>
        </template>
        <template v-else-if="passwordForgotMode">
          <ForgotPassword
            @complete="passwordForgotMode = false"
            @loading="authLoading = $event"
          />
          <div class="auth-link">
            <a @click="passwordForgotMode = false">
              Retour
            </a>
          </div>
        </template>
        <template v-else-if="!signUpMode">
          <!-- ======================== CONNEXION ======================== -->
          <Login
            @loading="authLoading = $event"
          >
            <div class="auth-link">
              <a @click="signUpMode = true">
                Pas de compte? Inscrivez-vous
              </a>
            </div>
            <div class="auth-link">
              <a @click="passwordForgotMode = true">
                Mot de passe oublié ?
              </a>
            </div>
          </Login>
        </template>
        <template v-else>
          <!-- ======================== INSCRIPTION ======================== -->
          <Signup
            @signup-complete="handleSignupComplete"
            @loading="authLoading = $event"
          />
          <div class="auth-link">
            <a @click="signUpMode = false">
              Vous avez un compte? Connectez-vous
            </a>
          </div>
        </template>
      </div>
    </main>
    <!-- ================================================ -->
    <CoachBot v-if="logged" />
    <footer
      :class="{
        [`page-${$route.name}`]: logged,
      }"
    >
      <div id="footer_bar">
        <div
          class="credits"
        >
          ©
          <a
            href="https://labsolu.net"
            target="_blank"
          >
            L'Absolu
          </a>
          -
          <a
            href="https://heckmann.dev"
            target="_blank"
          >
            Heckmann
          </a>
          - {{year}}
        </div>
      </div>
    </footer>
    <div
      v-if="!internetStatus || $store.state.requestQueue.length"
      id="internet_status"
    >
      <span v-if="statusTextDisplayed">
        Connexion avec le serveur: {{!internetStatus ? "interrompue": "OK"}}. <br />
        <template v-if="!internetStatus">
          Les données sont sauvegardées jusqu'à la reconnexion. ({{$store.state.requestQueue.length}})
        </template>
        <template v-else-if="$store.state.requestQueue.length > 0">
          {{$store.state.requestQueue.length}} requêtes en cours d'envoi.
        </template>
      </span>
      <i
        v-if="$store.state.requestQueue.length > 0 && internetStatus"
        class="fa fa-sync"
        @click="displayStatusText"
      />
      <i
        v-else
        class="fa fa-rss"
        @click="displayStatusText"
      >
        <i class="fa fa-ban" />
      </i>
    </div>

  </div>
  <div v-else>
    Chargement...
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Signup from "@/components/login/Signup.vue";
import Login from "@/components/login/Login.vue";
import CoachBot from "@/components/CoachBot.vue";
import RecoverPassword from "@/components/login/RecoverPassword.vue";
import ForgotPassword from "@/components/login/ForgotPassword.vue";
import { getPWADisplayMode, startTuto } from "@/utils";
import update from "@/mixins/update";

export default {
  name: "App",
  mixins: [update],
  components: {
    Signup,
    Login,
    RecoverPassword,
    ForgotPassword,
    CoachBot,
  },
  data() {
    return {
      pageLoading: true,
      menuOpened: false,
      signUpMode: false,

      passwordForgotMode: "",
      emailForgotPassword: "",

      toastDisplayed: false,
      toastText: "",
      toastIsError: false,

      authLoading: false,
      statusTextDisplayed: false,
      isInstalled: getPWADisplayMode() !== "browser",
    };
  },
  computed: {
    ...mapGetters([
      "user", "token", "isAdmin", "internetStatus", "isSmartWatch", "avatarShow", "pwaDeferredPrompt",
    ]),
    menu() {
      let menu = [
        {
          title: "Accueil",
          icon: "home",
          url: { name: "accueil" },
        },
        {
          title: "Radio",
          icon: "radio",
          url: { name: "radio" },
        },
      ];

      if (this.user.beta_accepted) {
        menu = [...menu,
          {
            title: "Entrainement",
            icon: "stopwatch",
            url: { name: "entrainement" },
          },
          {
            title: "Séances",
            icon: "list",
            sub: true,
            url: { name: "seances" },
          },
          {
            title: "Exercices",
            icon: "dumbbell",
            sub: true,
            url: { name: "exercices" },
          },
          {
            title: "Suivi",
            icon: "clipboard",
            url: { name: "calendar" },
            double: true,
          },
          {
            title: "Performances",
            icon: "stopwatch",
            sub: true,
            url: { name: "suivi" },
          },
          {
            title: "Photos",
            icon: "camera",
            sub: true,
            url: { name: "photos" },
          },
          {
            title: "Pesée",
            icon: "weight-scale",
            sub: true,
            url: { name: "pesee" },
          },
        ];
      }

      if (this.isAdmin) {
        menu.push({
          title: "Administration",
          icon: "user-shield",
          url: { name: "admin" },
        });

        menu.splice(2, 0, {
          title: "Lecteur",
          icon: "play-circle",
          sub: true,
          url: { name: "player-control" },
        });
      }

      return menu;
    },
    year() {
      return new Date().getFullYear();
    },
    logged() {
      return this.user && this.user.id && this.token;
    },
    debug: {
      set(val) {
        this.$store.commit("setDebug", val);
      },
      get() {
        return this.$store.getters.debug;
      },
    },
    tooltipContentUpdate() {
      window.updateApp = function updateApp(e) {
        document.querySelector(`img[aria-describedby='${e.parentElement.parentElement.id}'`).click();
      };

      return "<a onclick='window.updateApp(this)'>Une nouvelle version est disponible !<br />"
        + "Cliquez ici pour la récupérer automatiquement</a>";
    },
  },
  async mounted() {
    // ================= Internet status =================
    const checkOnlineStatus = async () => (
      this.$store.dispatch("checkOnlineStatus")
        .then(() => {
          this.$store.dispatch("setInternetStatus", true);
        })
        .catch((err) => {
          if (["ECONNABORTED", "ERR_NETWORK", "ERR_CANCELED"].includes(err.code)) {
            this.$store.dispatch("setInternetStatus", false);
          } else {
            this.$store.dispatch("setInternetStatus", true);
          }
        })
    );

    await checkOnlineStatus();
    setInterval(() => {
      checkOnlineStatus();
    }, 15000);

    window.addEventListener("offline", () => { this.$store.dispatch("setInternetStatus", false); });
    window.addEventListener("online", () => { this.$store.dispatch("setInternetStatus", true); });
    // ================= Internet status =================

    if (this.token) {
      await this.$store.dispatch("verifyToken")
        .then(() => {
          this.pageLoading = false;
        })
        .catch(() => {
          this.pageLoading = false;
        });
    } else {
      this.pageLoading = false;
    }
    this.$store.commit("setToastHandler", this.displayToast);

    this.$nextTick(() => {
      document.querySelector("#content").addEventListener("scroll", this.handleToastScroll);
    });

    // Initialize deferredPrompt for use later to show browser install prompt.
    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();

      // Stash the event so it can be triggered later.
      this.$store.commit("setPwaDeferredPrompt", e);

      let lastAsk = localStorage.getItem("pwaLastAsk");

      if (lastAsk) {
        lastAsk = new Date(parseInt(lastAsk, 10));

        const fiveDaysAgo = new Date();
        fiveDaysAgo.setDate(fiveDaysAgo.getDate() - 5);

        // Si on a déjà demandé il y a 5 jours, on ne redemande pas.
        if (fiveDaysAgo > lastAsk) {
          localStorage.setItem("pwaLastAsk", new Date().getTime());
          this.$router.push({ name: "install" });
        }
      }
    });
    window.addEventListener("appinstalled", () => {
      // Clear the deferredPrompt so it can be garbage collected
      this.$store.commit("setPwaDeferredPrompt", null);
    });

    setTimeout(() => {
      const hour = new Date().getHours();

      if (hour === 0 && this.updateExists) {
        this.registration.update();
        this.refreshApp();
      }
    }, 30 * 60 * 60);
  },
  methods: {
    startTuto,
    handleToastScroll() {
      const toast = document.querySelector(".toast");
      if (toast) {
        if (this.user) {
          const header = document.querySelector("header");

          if (document.querySelector("#content").scrollTop >= header.clientHeight) {
            toast.classList.add("fixed");
          } else {
            toast.classList.remove("fixed");
          }
        } else {
          toast.classList.add("fixed");
        }
      }
    },
    displayStatusText() {
      this.statusTextDisplayed = true;
      setTimeout(() => {
        this.statusTextDisplayed = false;
      }, 5000);
    },
    handleSignupComplete() {
      this.signUpMode = false;
    },
    displayToast(text, isError = false, duration = 3000) {
      this.toastText = text;
      this.toastIsError = isError;
      this.toastDisplayed = true;
      this.$nextTick(() => this.handleToastScroll());

      setTimeout(() => {
        this.toastDisplayed = false;
      }, duration);
    },
    toggleMenu() {
      this.menuOpened = !this.menuOpened;
    },
    logout() {
      localStorage.removeItem("uuidSw");
      this.$store.dispatch("logout")
        .then(() => {
          this.menuOpened = false;
        });
    },
  },
};

</script>

<style lang="scss">
@use "@/assets/variables.scss" as var;
@import "@/assets/style.scss";

.login-error {
  color: var.$color-error;
  text-align: center;
}

.credits.darker {
  background: var.$red-darker;
}

#internet_status {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
  margin: .5rem;
  padding: .8rem 1rem .8rem 1.2rem;
  background: white;
  color: var.$color-error;
  border-radius: var.$border-radius;
  display: flex;
  align-items: center;
  span {
    margin-right: 1rem;
    text-align: center;
  }
  .fa-rss {
    position: relative;
    color: #000;
  }
  .fa-ban {
    position: absolute;
    top: -5px;
    left: -8px;
    font-size: 200%;
    color: rgba(217, 83, 79, 0.7);
  }
}

#enable_debug {
  margin-top: 2rem;
  label {
    align-items: center;
    display: flex;
    input {
      margin-right: 1rem;
    }
  }
}

.tutorial-target {
  content: "";
  position: absolute;
  box-shadow: 0 0 0 max(200vh, 200vw) rgba(0, 0, 0, .5);
  // pointer-events: none;
  z-index: 99999;
  cursor: pointer;
  border-radius: 3rem;
}

.tutorial-text {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  width: calc(100% - 2rem);
  background: rgba(255, 255, 255, 0.9);
  z-index: 99999;
  padding: 2rem 1rem 1rem 1rem;
  text-align: center;
  border-radius: var.$border-radius;
  &.top {
    bottom: unset;
    top: 1rem;
  }
  button {
    display: block;
    margin-top: 2rem;
    width: 100%;
  }
}

.tooltip-update a {
  color: white;
  cursor: pointer;
}
</style>
