import axios from "axios";
import config from "@/config";
import store from "@/store";


export default (objConfigParam) => {
  const objConfig = objConfigParam || {};

  let headers = {};
  if (!objConfig.noHeader) {
    // Ne pas mettre le header ( dans le cas du Login par exemple )
    headers = {
      Authorization: `Token ${store.getters.token}`,
    };
  }

  const instance = axios.create({
    baseURL: config.api,
    timeout: objConfig.timeout || 20000,
    headers,
    queue: objConfig.queue,
  });

  instance.interceptors.request.use(function (config) {
    if (
      config.queue
      && !["get", "options"].includes(config.method)
      && !store.getters.isRequestQueueEmpty
    ) {
      store.dispatch("storeRequest", {
        url: config.url,
        method: config.method,
        data: config.data,
      });
      throw new axios.Cancel("Requête ajoutée à la queue");
    }
    return config;
  }, null);

  // Afficher le contenu si pas d'erreur
  instance.interceptors.response.use(
    // (data) => {
    //   if (data.config.method === "get" && !data.config.url.includes("?checkingInternet")) {
    //     const key = `${data.config.method} ${data.config.url}`;
    //     localStorage.setItem(key, JSON.stringify(data.data));
    //   }
    //   return data;
    // },
    null,
    // Si erreur 401 rediriger vers login et déconnecter l'user en session
    (error) => {
      // alert(JSON.stringify(error));
      if (error.response && error.response.status === 403) {
        store.commit("setToken", null); // vide le token dans le store
        store.commit("setUser", null); // vide le usernamer dans le store
      }
      else if (["ECONNABORTED", "ERR_NETWORK"].includes(error.code)) {
        store.dispatch("setInternetStatus", false);
        if (
          error.config.queue
          && !["get", "option"].includes(error.config.method)
          && !["/verify_token"].find((u) => error.config.url.includes(u))
        ) {
          const body = JSON.parse(error.config.data)
          store.dispatch("storeRequest", {
            url: error.config.url,
            method: error.config.method,
            data: body,
          });

          return Promise.resolve({data: {queued: true}});
        } else if (error.config.method === "get") {
          // const key = `${error.config.method} ${error.config.url}`;
          // const cache = localStorage.getItem(key);
          // if (cache) {
          //   return Promise.resolve(
          //     { data: JSON.parse(cache) }
          //   );
          // }
        }

        if(!error.config.url.includes("?checkingInternet")) {
          // store.dispatch("displayToast", {
          //   text:"Vous semblez être hors-ligne",
          //   isError: true,
          // });
        }
      }
      else if (error.code === "CanceledError") {
        return Promise.resolve({data: {queued: true}});
      }

      return Promise.reject(error);
    },
  );
  return instance;
};
