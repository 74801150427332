<template>
  <PulseLoader
    v-if="!table.publics.seances.length && !table.privee.seances.length && !table.shared.seances.length"
    loading
  />
  <div v-else-if="!seances.length">
    <h2>Aucune séance configurée</h2>
  </div>
  <div v-else>
    <div>
      <h2>
        Créer une séance à la volée
      </h2>
      <p>
        Envie de varier aujourd'hui? Ce mode vous permet d'ajouter les exercices au fur et à mesure.
      </p>
      <div class="button-container">
        <button
          @click.prevent="startSeanceCustom"
        >
          C'est parti
        </button>
      </div>
    </div>
    <div
      id="seance_select"
    >
      <div class="top">
        <h2>
          Lancer une séance configurée
        </h2>
        <p>
          Permet d'enchaîner plusieurs exercices préalablement paramétrés afin de suivre votre
          évolution sur le long terme.&nbsp;Vous retrouverez l'ensemble de vos
          séances&nbsp;<router-link :to="{name:'seances', }">ici</router-link>.
        </p>
      </div>
      <div
        v-for="typ in types"
        v-if="table[typ].seances.length"
        :key="typ"
      >
        <h3>{{ table[typ].nom }}</h3>
        <ul
          class="seances"
        >
          <li
            v-for="seance in table[typ].seances"
            :key="seance.id"
            @click="startSeance(seance, )"
          >
            <img
              class="icon"
              v-if="seance.icon"
              :src="require(`@/assets/muscles/${seance.icon}.png`, )"
            />
            <div class="infos">
              <div class="titre">
                <div>
                  <strong>{{seance.nom}}</strong>
                  <template v-if="seance.jourVerbose">
                    &middot; {{seance.jourVerbose}}
                  </template>
                  <template v-if="false && typ === 'shared'">
                    &middot; Partagé par {{seance.user_created.first_name}} {{seance.user_created.last_name}}
                  </template>
                </div>
                <div v-if="seance.public">
                  <img :src="require('@/assets/logo.svg', )" />
                </div>
              </div>
              <div
                v-if="seance.commentaire"
                class="commentaire"
              >
                {{seance.commentaire}}
              </div>
            </div>
          </li>
        </ul>
        <Pagination
          v-model="table[typ].currentPage"
          :total-results="table[typ].resultsCount"
          @paginate="table[typ].method"
          :per-page="perPage"
        />
      </div>
    </div>
    <div v-if="false">
      <button @click="$store.dispatch('faceExpression', 'reset', )">Reset</button>
      <button @click="$store.dispatch('faceExpression', 'very-happy', )">Très heureux</button>
      <button @click="$store.dispatch('faceExpression', 'happy', )">Heureux</button>
      <button @click="$store.dispatch('faceExpression', 'sad', )">Triste</button>
      <button @click="$store.dispatch('faceExpression', 'surprised', )">Étonné</button>
      <button @click="$store.dispatch('faceExpression', 'thinking', )">Thinking</button>
      <button @click="$store.dispatch('faceExpression', 'look-up-left', )">Regarder en haut à gauche</button>
      <button @click="$store.dispatch('faceExpression', 'look-up-right', )">Regarder en haut à droite</button>
      <button @click="$store.dispatch('faceExpression', 'look-left', )">Regarder à gauche</button>
      <button @click="$store.dispatch('faceExpression', 'look-right', )">Regarder à droite</button>
      <button @click="$store.dispatch('faceExpression', 'blink', )">Cligné</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Api from "@/modules/axios";
import Pagination from "@/components/Pagination.vue";

export default {
  name: "StartEntrainement",
  components: {
    Pagination,
  },
  computed: {
    ...mapGetters(["user"]),
    seances() {
      return [
        ...this.table.publics.seances,
        ...this.table.privee.seances,
        ...this.table.shared.seances,
      ];
    },
  },
  data() {
    return {
      perPage: 10,
      table: {
        privee: {
          nom: "Mes séances",
          currentPage: 1,
          resultsCount: 0,
          seances: [],
          method: this.getSeancesPrivees,
        },
        publics: {
          nom: "Séances fournies par l'Absolu",
          currentPage: 1,
          resultsCount: 0,
          seances: [],
          method: this.getSeancesPublics,
        },
        shared: {
          nom: "Séances partagées",
          currentPage: 1,
          resultsCount: 0,
          seances: [],
          method: this.getSeancesShared,
        },
      },
      types: ["privee", "shared", "publics"],
    };
  },
  mounted() {
    if (window.isTuto) {
      Api().get("/seance/demo/")
        .then((res) => {
          this.$set(this.table.publics, "seances", [res.data]);
          this.$set(this.table.publics, "resultsCount", 1);
        });
    } else {
      Promise.all([
        this.getSeancesPublics(),
        this.getSeancesPrivees(),
        this.getSeancesShared(),
      ]).then(() => {
        const seances = [
          ...this.table.privee.seances,
          ...this.table.publics.seances,
          ...this.table.shared.seances,
        ];
        this.cacheAll(seances);
      });
    }
  },
  methods: {
    getSeancesPublics() {
      return Api().get(
        `/seance/?public=true&page_size=${this.perPage}&page=${this.table.publics.currentPage}`,
      )
        .then((res) => {
          this.$set(this.table.publics, "seances", res.data.results);
          this.$set(this.table.publics, "resultsCount", res.data.count);
        });
    },
    getSeancesPrivees() {
      return Api().get(
        `/seance/?only_private=true&public=false&page_size=${this.perPage}&page=${this.table.privee.currentPage}`,
      )
        .then((res) => {
          this.$set(this.table.privee, "seances", res.data.results);
          this.$set(this.table.privee, "resultsCount", res.data.count);
        });
    },
    getSeancesShared() {
      return Api().get(
        `/seance/?only_shared=true&public=false&page_size=${this.perPage}&page=${this.table.privee.currentPage}`,
      )
        .then((res) => {
          this.$set(this.table.shared, "seances", res.data.results);
          this.$set(this.table.shared, "resultsCount", res.data.count);
        });
    },
    startSeance(seance) {
      if (window.isTuto) {
        Api().get("/seance/demo/")
          .then((res) => {
            this.$emit("start-seance", res.data);
          });
      } else {
        Api().get(`/seance/${seance.id}/`)
          .then((res) => {
            // console.log(JSON.stringify(res.data));
            this.$emit("start-seance", res.data);
          });
      }
    },
    startSeanceCustom() {
      this.$emit("start-seance", {
        nom: "Séance personnalisée",
        seance_exercices: [],
      });
    },
    cacheAll(seances) {
      // TODO
      // Api().get("")
      console.log(seances);
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/variables.scss" as var;

.start-screen {
  h2 {
    margin-top: 1rem;
  }
  p {
    text-align: center;
    margin-bottom: 1.5rem;
  }
  .button-container {
    margin-top: 0;
  }
}

#seance_select {
  h2 {
    margin-top: 3rem;
  }
  ul.seances li {
    padding: 1rem;

    background: var.$background-gray;
    border: 1px solid var.$border-gray;
    border-radius: var.$border-radius;
    cursor: pointer;

    display: flex;
    align-items: center;

    & + li {
      margin-top: 0.5rem;
    }

    .icon {
      margin-right: 1rem;
      height: 4rem;
      width: 4rem;
    }
    .infos {
      width: 100%;
      .commentaire {
        margin-top: 1rem;
      }
      .titre {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      img {
        width: 1rem;
        height: 1rem;
      }
    }
  }

}
</style>
