const ws = window.location.protocol === "https:" ? "wss" : "ws";

const BASE_URL = `${window.location.protocol}//${window.location.hostname}:8005`;

export default {
  // api: "http://google.com:81/", // Pour timeout
  baseUrl: BASE_URL,
  api: `${BASE_URL}/api`,
  socketBaseUrl: `${ws}://${window.location.hostname}:8005/ws`,
};
