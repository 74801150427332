<template>
  <div
    v-if="groupStep.steps.length"
    :class="{
      active: groupStep.steps && groupStep.steps.find(
        (s, ) => s.id === currentStep.id,
      ),
      superset: superset,
    }"
  >
    <div class="exo-title">
      <span>
        {{groupStep.nom}}
        <i
          class="fa fa-info-circle"
          @click="$emit('display-modal', groupStep.steps[0], )"
        />
      </span>
    </div>
    <ul class="steps">
      <Step
        v-for="(step, stepIndex) in groupStep.steps"
        :key="step.id"
        :current-step="currentStep"
        :step="step"
        :chrono="chrono"
        @next-step="$emit('next-step', $event, )"
        @edit-modifier="$emit('edit-modifier', $event, )"
        @skip-chrono="$emit('skip-chrono', $event, )"
        @change="$emit('change', $event, )"
        @suppr="supprSerie(stepIndex, )"
      />
      <li v-if="!superset">
        <i
          class="fa fa-add"
          @click="addSerie"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import Step from "@/components/Step.vue";
import Api from "@/modules/axios";
import { trimDecimalsIfNeeded } from "@/utils";

export default {
  name: "StepGroup",
  components: {
    Step,
  },
  props: {
    groupStep: Object,
    currentStep: Object,
    chrono: Number,
    superset: Boolean,
  },
  methods: {
    supprSerie(stepIndex) {
      this.groupStep.steps.splice(stepIndex, 1);
    },
    addSerie() {
      const lastSerie = this.groupStep.steps.at(-1);
      return Api().get(`/exercice/${lastSerie.exercice.id}/last_perf/?series=${lastSerie.serie + 1}`)
        .then((res) => {
          this.groupStep.steps.push({
            ...lastSerie,
            serie: lastSerie.serie + 1,
            id: `${lastSerie.exercice.id}add${lastSerie + 1}${Math.random()}`,
            added: true,
            ordre: lastSerie.ordre + 1,
            last_perfs: res.data[0],
            poids: trimDecimalsIfNeeded(res.data[0].last.poids),
            reps: res.data[0].last.reps,
            perfId: null,
          });
        });
    },
  },
};
</script>

<style>

</style>
