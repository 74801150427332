<template>
  <div class="body-part-selector">
    <Body />
    <div
      v-if="!disabled"
      class="select-controls"
    >
      <div>
        <div>
          <button
            class="select-primary"
            @click.prevent="mode = 'primary'"
            :class="{ 'active' : mode === 'primary', }"
          >
            <i class="fa fa-circle"/>
          </button>
          <button
            class="reset-primary"
            @click.prevent="clear('primary', )"
          >
            <i class="fa fa-arrow-rotate-left"/>
          </button>
        </div>
        <div>Principaux</div>
      </div>
      <div>
        <div>
          <button
            class="select-secondary"
            @click.prevent="mode = 'secondary'"
            :class="{ 'active' : mode === 'secondary', }"
          >
            <i class="fa fa-circle"/>
          </button>
          <button
            class="reset-secondary"
            @click.prevent="clear('secondary', )"
          >
            <i class="fa fa-arrow-rotate-left"/>
          </button>
        </div>
        <div>Secondaire</div>
      </div>
    </div>
  </div>
</template>

<script>

import Body from "@/components/Body.vue";

export default {
  name: "VueBodyPartSelector",
  model: {
    props: "value",
    event: "change",
  },
  components: {
    Body,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selection: {},
      mode: "primary",
      color: {
        primary: "#a11b22",
        secondary: "#bf8488",
      },
    };
  },
  mounted() {
    this.bindEvents();
  },
  watch: {
    value: {
      handler(newVal) {
        this.$nextTick(() => {
          Object.keys(newVal).forEach((k) => {
            const tmp = document.querySelectorAll(`#${k}`);
            [].map.call(tmp, (element) => {
              element.style.fill = newVal[k] ? this.color[newVal[k]] : element.initialFill;
            });
          });
        });
      },
      immediate: true,
    },
  },
  methods: {
    update(edit) {
      const newVal = { ...this.value, ...edit };

      Object.keys(newVal).forEach((k) => {
        const tmp = document.querySelectorAll(`#${k}`);
        [].map.call(tmp, (element) => {
          element.style.fill = this.color[this.mode] || element.initialFill;
        });
      });
      this.$emit("change", newVal);
    },
    bindEvents() {
      const paths = document.querySelectorAll(".body-part-selector path");
      paths.forEach((path) => {
        path.initialFill = path.style.fill;
        path.addEventListener("click", () => {
          if (!this.disabled) {
            this.update({ [path.id]: (this.value[path.id] === this.mode ? false : this.mode) });
          }
        });
      });
    },
    clear(mode) {
      const obj = {};
      Object.keys(this.value).forEach((k) => {
        if (this.value[k] === mode) {
          obj[k] = false;
        }
      });
      this.update(obj);
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/variables.scss" as var;

.body-part-selector * {
  // sur safari mobile quand on click sur un muscle ça fait un truc bleu autour
  // https://stackoverflow.com/questions/62948004/how-to-remove-blue-box-fill-when-clicking-the-buttons
  -webkit-tap-highlight-color: transparent;
}
.select-controls {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  gap: 3rem;
  align-items: center;
  > * {
    text-align: center;
    font-weight: bold;
  }
  button {
    background: var.$background-gray;
    border: 1px solid var.$border-gray;
    border-radius: var.$border-radius;
    padding: .5rem 2rem;
    cursor: pointer;
  }
  button + button {
    margin-left: 0.5rem;
  }

  .select-secondary, .reset-secondary {
    color: #bf8488;
  }
  .select-primary, .reset-primary {
    color: #a11b22;
  }

  .active {
    border: 1px solid #a11b22;
  }
}
</style>
