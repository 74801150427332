<template>
<div>
  <div v-if="noConnection">
    <div
      class="img-container"
    >
      <div>
        <h2>Une connexion internet est nécessaire afin de vérifier votre identité</h2>
        <img :src="require('@/assets/internet.png', )" />
        <a
          class="blue-link"
          @click="reset"
        >
          Réessayer
        </a>
      </div>
    </div>
  </div>
  <div v-else>
    <div v-if="isSmartWatch">
      <SmartWatchCode />
    </div>
    <form v-else>
      <h2>Connexion</h2>
      <label>Adresse email&nbsp;:</label>
      <input
        v-model="username"
        type="email"
      />
      <label>Mot de passe&nbsp;:</label>
      <input
        v-model="password"
        type="password"
      />
      <button @click.prevent="login">Connexion</button>
      <slot />
    </form>
  </div>
</div>
</template>

<script>
import { mapGetters } from "vuex";
import SmartWatchCode from "@/components/SmartWatchCode.vue";

export default {
  name: "Login",
  components: {
    SmartWatchCode,
  },
  data() {
    return {
      username: "",
      password: "",
      noConnection: false,
    };
  },
  beforeDestroy() {
    this.$emit("loading", false);
  },
  computed: {
    ...mapGetters(["isSmartWatch", "internetStatus", "token"]),
    isMobile() {
      return window.matchMedia("(max-width: 600px)").matches;
    },
  },
  mounted() {
    this.noConnection = !this.internetStatus;
  },
  methods: {
    reset() {
      if (this.token) {
        window.location.reload();
      } else {
        this.noConnection = false;
      }
    },
    login() {
      this.$emit("loading", true);
      return this.$store.dispatch("login", {
        username: this.username.trim(),
        password: this.password,
      })
        .then((logged) => {
          this.$emit("loading", false);
          this.$router.push({ name: "accueil" });
          setTimeout(() => {
            if (!logged) {
              this.$toast("Connexion impossible: identifiants incorrects", true);
            }
          }, 200);
        })
        .catch((err) => {
          console.log(err);
          if (["ECONNABORTED", "ERR_NETWORK", "ERR_CANCELED"].includes(err.code)) {
            this.noConnection = true;
            this.$emit("loading", false);
          } else if (err.response) {
            if (err.response.status === 404) {
              this.$toast("Connexion impossible: identifiants incorrects", true);
              this.$emit("loading", false);
            }
          }
        });
    },
  },
};
</script>

<style>

</style>
