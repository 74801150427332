<template>
  <li
    class="step"
    :class="{
      active: step.id === currentStep.id,
      'chrono-running': chrono > 0,
      'no-repos' : !step.duree_repos,
    }"
  >
    <div v-if="debug">
      <details>
        <summary>step</summary>
        {{step}}
      </details>
      <details>
        <summary>currentStep</summary>
        {{currentStep}}
      </details>
      <div>current/actuel ordre: {{currentStep.ordre}} {{step.ordre}}</div>
      <div>current/actuel id: {{currentStep.id}} {{step.id}}</div>
      <div>chrono: {{chrono}}</div>
      <div>perfId: {{step.perfId || 'undefined'}}</div>
      <div>disableInput: {{disableInput}} (s.o &lt; cs.o && !perfId)</div>
    </div>
    <div class="step-inputs">
      <div>
        <span v-tooltip="tooltipContent"> <!-- nécessaire pour tooltip sur désactivé -->
          <input
            class="kg"
            type="number"
            value="200"
            step="0.01"
            v-model="step.poids"
            @change="$emit('change', step, )"
            :disabled="disableInput"
          />
        </span>
        <label>kg</label>
      </div>
      <div>
        <span v-tooltip="tooltipContent">
          <input
            class="reps"
            type="number"
            value="10"
            step="0.01"
            v-model="step.reps"
            @change="$emit('change', step, )"
            :disabled="disableInput"
          />
        </span>
        <label>Reps ({{step.nb_repetition}} prévu)</label>
        <i
          class="fa fa-times suppr"
          @click="$emit('suppr', )"
        />
      </div>
    </div>
    <div
      v-if="step.last_perfs && step.last_perfs.historique.length"
      class="historique-poids"
    >
      Historique&nbsp;:<br/>
      <span
        v-for="(perf, index) in step.last_perfs.historique[0]"
        :key="index"
      >
        {{perf[1]}}x{{trimDecimalsIfNeeded(perf[0],)}}kg{{
          index === step.last_perfs.historique[0].length - 1 ? "" : ", "
        }}
      </span>
    </div>
    <div
      v-if="step.commentaire"
      class="modifiers"
    >
      <div
        v-if="displayModifiers || degressif"
        :class="{'active': degressif, 'disabled': !displayModifiers, }"
        @click="displayModifiers ? $emit('edit-modifier', [step, 'degressif', ], ) : null"
      >
        <template v-if="degressif === 'loading'">
          <PulseLoader size="10px" />
        </template>
        <template v-else>
          <i class="fa fa-arrow-trend-down" />
          Dégressif {{
            step.commentaire
              && step.commentaire.seance_exercice && !step.commentaire.degressif && step.degressif ? '*' : ''
          }}
        </template>
      </div>
      <div
        v-if="displayModifiers || longue"
        :class="{'active': longue, 'disabled': !displayModifiers,  }"
        @click="displayModifiers ? $emit('edit-modifier', [step, 'longue', ], ) : null"
      >
        <template v-if="longue === 'loading'">
          <PulseLoader size="10px" />
        </template>
        <template>
          <i class="fa fa-stopwatch" />
          Isométrique {{
            step.commentaire
              && step.commentaire.seance_exercice && !step.commentaire.longue && step.longue ? '*' : ''
            }}
        </template>
      </div>
    </div>
    <button
      v-if="step.id === currentStep.id && chrono === 0"
      class="next-step btn"
      @click="$emit('next-step', $event, )"
    >
      Suivant
    </button>
    <div
      v-if="step.duree_repos"
      class="repos"
    >
      Repos pendant {{step.duree_repos}} s
      <a
        v-if="chrono > 0 && step.id === currentStep.id"
        class="skip-repos"
        @click="$emit('skip-chrono', $event, )"
      >
        Passer
      </a>
    </div>
  </li>
</template>

<script>
import { trimDecimalsIfNeeded } from "@/utils";
import { mapGetters } from "vuex";

export default {
  name: "Step",
  props: {
    currentStep: Object,
    step: Object,
    chrono: Number,
  },
  methods: {
    trimDecimalsIfNeeded,
  },
  computed: {
    ...mapGetters(["debug"]),
    disableInput() {
      return this.step.ordre < this.currentStep.ordre && !this.step.perfId;
    },
    tooltipContent() {
      return {
        trigger: "hover focus click",
        content: this.disableInput ? "Impossible de modifier cette valeur" : "",
      };
    },
    displayModifiers() {
      return this.step.id === this.currentStep.id && this.chrono === 0;
    },
    degressif() {
      return (this.step.commentaire && this.step.commentaire.seance_exercice
        ? this.step.commentaire.degressif
        : this.step.degressif);
    },
    longue() {
      return (this.step.commentaire && this.step.commentaire.seance_exercice
        ? this.step.commentaire.longue
        : this.step.longue);
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/variables.scss" as var;

</style>
