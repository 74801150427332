<template>
  <PulseLoader
    v-if="loading"
    loading
  />
  <div v-else-if="addingDevice">
    <a
      class="blue-link"
      @click="addingDevice = false"
    >
      <i class="fa fa-arrow-left" />
      Retour
    </a>
    <p>Scanner le QRCode affiché sur l'autre appareil (Smartwatch).</p>
    <div class="camera">
      <div
        v-if="cameraLoading"
        class="wait-camera"
      >
        <div
          class="img-container"
        >
          <div>
            <h2>Veuillez patienter<br />chargement de la caméra</h2>
            <img :src="require('@/assets/camera.png', )" />
          </div>
        </div>

      </div>
      <QrcodeStream
        @init="initCamera"
        @decode="handleScanSuccess"
      />
    </div>
  </div>
  <div v-else-if="internalUser">
    <h2>
      Votre profil
    </h2>
    <form id="user_form">
      <div class="figure-container">
        <input
          id="file_avatar"
          type="file"
          @change="handleAvatarChange"
        />
        <label for="file_avatar">
          <figure>
            <div class="edit">
              <i
                class="fa fa-edit"
              />
            </div>
            <img
              v-if="internalUser.avatar"
              :src="internalUser.avatar"
            />
            <i
              v-else
              class="fa fa-user"
              id="profile_link"
            />
          </figure>
        </label>
      </div>
      <div class="form-field">
        <label>Nom&nbsp;:</label>
        <input v-model="internalUser.last_name" />
      </div>
      <div class="form-field">
        <label>Prénom&nbsp;:</label>
        <input v-model="internalUser.first_name" />
      </div>
      <div class="form-field">
        <label>Email&nbsp;:</label>
        <input v-model="internalUser.email" />
      </div>
      <!-- Désactivation smartwatch -->
      <div
        v-if="false"
        class="button-container add-device"
      >
        <button
          class="secondary"
          @click="addingDevice=true"
        >
          {{ internalUser.sw_uuid_allowed ? "Remplacer l'appareil autorisé" : "Autoriser un appareil"}}
        </button>
      </div>
    </form>
    <div class="button-container">
      <button @click="save">Enregistrer</button>
    </div>
  </div>
</template>

<script>
import Api from "@/modules/axios";
import { mapGetters } from "vuex";
import { catchError } from "@/utils";

export default {
  name: "Profile",
  components: {
    QrcodeStream: () => import("vue-qrcode-reader").then((res) => res.QrcodeStream),
  },
  data() {
    return {
      loading: true,
      internalUser: null,
      addingDevice: false,
      cameraLoading: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  mounted() {
    this.$store.commit("setAvatarShow", false);
    this.internalUser = {
      last_name: this.user.last_name,
      first_name: this.user.first_name,
      email: this.user.email,
      avatar: this.user.avatar,
      sw_uuid_allowed: this.user.sw_uuid_allowed,
    };
    this.loading = false;
  },
  methods: {
    back(event) {
      if (this.newShareMode) {
        event.preventDefault();
        this.newShareMode = false;
      }
    },
    handleAvatarChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      this.saveImg(files[0]);
    },
    saveImg(file) {
      const data = new FormData();
      data.append("avatar", file);

      Api().patch(
        `/utilisateur/${this.user.id}/`,
        data,
      )
        .then((res) => {
          this.$store.commit("setUser", res.data);

          this.internalUser = {
            last_name: this.user.last_name,
            first_name: this.user.first_name,
            email: this.user.email,
            avatar: this.user.avatar,
          };
          this.$toast("Image enregistrée");
        });
    },
    save() {
      this.internalUser.username = this.internalUser.email;
      const data = { ...this.internalUser };
      delete data.avatar;
      delete data.subscribe_asked;

      Api().patch(
        `/utilisateur/${this.user.id}/`,
        data,
      )
        .then((res) => {
          this.$store.commit("setUser", res.data);
          this.internalUser = {
            last_name: this.user.last_name,
            first_name: this.user.first_name,
            email: this.user.email,
            avatar: this.user.avatar,
          };
          this.$toast("Sauvegarde effectuée");
        });
    },
    initCamera(promise) {
      this.cameraLoading = true;
      promise.then(() => {
        this.cameraLoading = false;
      });
    },
    handleScanSuccess(token) {
      const deviceUuid = token.replace("smartwatch-", "");
      this.addingDevice = false;
      Api().patch(
        `/utilisateur/${this.user.id}/`,
        { sw_uuid_allowed: deviceUuid },
      )
        .then(() => {
          this.$toast("Appareil autorisé");
        })
        .catch(catchError);
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/variables.scss" as var;

#user_form {
  .figure-container {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
    figure {
      position: relative;
      border-radius: var.$border-radius;
      margin: 0;
      width: 6rem;
      height: 6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
        border-radius: var.$border-radius;
        overflow: hidden;
      }

      i:not(.fa-edit){
        font-size: 2rem;
        background: var.$red-darker;
        padding: 1.5rem;
        border-radius: 30%;
        color: white;
      }

      &:hover .edit {
        display: flex;
        cursor: pointer;
      }

      .edit {
        position: absolute;
        justify-content: center;
        align-items: center;
        background: var.$transparent-light;
        // display: flex;
        display: none;
        width: 100%;
        height: 100%;
        .fa {
          font-size: 1.5rem;
        }
      }
    }
  }
  #file_avatar {
    display: none;
  }
}

.button-container.add-device {
  margin-top: 1rem;
  button.secondary {
    font-size: 1rem;
  }
}

.camera {
  position: relative;
  .wait-camera {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; height: 100%;
    .img-container > div img {
      height: 50%;
    }
  }
}
.qrcode-stream-wrapper{
  border-radius: var.$border-radius;
  overflow: hidden;
}
</style>
