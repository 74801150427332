<template>
  <div>
    <ResumeEntrainement
      v-if="isResume"
      :mountedSaveSeance="mountedSaveSeance"
      @resume="resumeSeance"
      @giveup="giveupSeance"
    />
    <StartEntrainement
      v-else-if="!currentSeance"
      class="start-screen"
      @start-seance="startSeance"
    />
    <AddExoEntrainement
      v-else-if="isAddingExo"
      :currentStep="currentStep"
      @add-exo="addExo"
      @cancel="isAddingExo = false"
    />
    <EndEntrainement
      v-else-if="!currentStep"
      :empty="!currentSeance.steps.length"
      :seance="currentSeance"
      @reset="resetBack"
      @add-exo="isAddingExo=true"
      @serie-back="goBackSerie"
    />
    <div
      v-else
      class="fullscreen-train"
    >
      <div id="minimal_toggle_container">
        <div
          id="minimal_toggle"
          @click="minimalistView = !minimalistView"
        >
          <button :class="{'active': !minimalistView, }">
            <i class="fa fa-list-ul"/>
          </button>
          <button
            :class="{'active': minimalistView, }"
          >
            <i class="fa fa-grip"/>
          </button>
        </div>
      </div>
      <div
        id="minimalist_view"
        v-if="minimalistView"
      >
        <div
          class="chrono"
          v-if="chrono"
          >
          <div
            class="counter"
            :class="{'reduced': !chronoExtended, }"
            @click="chronoExtended = !chronoExtended"
          >
            {{chrono}}
          </div>
          <button
            class="btn secondary"
            @click="skipChrono(); currentStepIndex += 1;"
          >
            Passer
          </button>
        </div>
        <div v-if="!currentStepMinimalist">
          fin
        </div>
        <div
          class="step warmup"
          v-else-if="currentStepMinimalist.type === 'warmup'"
        >
          <h3>Échauffement</h3>
          <i class="fa-solid fa-temperature-high" />
          <textarea
            :value="currentStepMinimalist.commentaire.texte || currentStepMinimalist.notes_echauffement"
            placeholder="Non défini. Exemple : 10 minutes de rameur puis une série à vide."
            rows="4"
            @change="saveInfoEchauffement(currentStepMinimalist, )"
          />
          <button
            class="next-step btn"
            @click="nextStep"
          >
            Suivant
          </button>
        </div>
        <div
          class="step exo"
          v-else-if="currentStepMinimalist.type === 'exo'"
        >
          <h3>
            {{ currentStepMinimalist.exercice.nom }}
            <i
              class="fa fa-info-circle"
              @click="displayModal(currentStepMinimalist, )"
            />
          </h3>
          <img :src="currentStepMinimalist.exercice.img1" />
          <div>
            <div class="kg-reps">
              <div class="input-container">
                <strong>Charge</strong>
                <input
                  class="kg"
                  type="number"
                  value="200"
                  step="0.01"
                  v-model="currentStepMinimalist.poids"
                  @change="$emit('change', currentStepMinimalist, )"
                />
                <i class="info">kg</i>
              </div>
              <div class="input-container">
                <strong>Répétitions</strong>
                <input
                  class="reps"
                  type="number"
                  value="10"
                  step="0.01"
                  v-model="currentStepMinimalist.reps"
                  @change="$emit('change', currentStepMinimalist, )"
                />
                <i class="info">
                  <template v-if="currentStepMinimalist.nb_repetition">
                    ({{currentStepMinimalist.nb_repetition}} prévu)
                  </template>
                </i>
              </div>
            </div>
            <div
              v-if="currentStepMinimalist.last_perfs && currentStepMinimalist.last_perfs.historique.length"
              class="historique-poids"
            >
              Historique&nbsp;:<br/>
              <span
                v-for="(perf, index) in currentStepMinimalist.last_perfs.historique[0]"
                :key="index"
              >
                {{perf[1]}}x{{trimDecimalsIfNeeded(perf[0],)}}kg{{
                  index === currentStepMinimalist.last_perfs.historique[0].length - 1 ? "" : ", "
                }}
              </span>
            </div>
          </div>
          <div
            v-if="currentStepMinimalist.commentaire"
            class="modifiers"
          >
            <div
              :class="{'active': degressif, }"
              @click="editModifier([currentStepMinimalist, 'degressif', ], )"
            >
              <template v-if="degressif === 'loading'">
                <PulseLoader size="10px" />
              </template>
              <template v-else>
                <i class="fa fa-arrow-trend-down" />
                Dégressif {{
                  currentStepMinimalist.commentaire
                    && currentStepMinimalist.commentaire.seance_exercice
                    && !currentStepMinimalist.commentaire.degressif
                    && currentStepMinimalist.degressif ? '*' : ''
                }}
              </template>
            </div>
            <div
              :class="{'active': longue, }"
              @click="editModifier([currentStepMinimalist, 'longue', ], )"
            >
              <template v-if="longue === 'loading'">
                <PulseLoader size="10px" />
              </template>
              <template>
                <i class="fa fa-stopwatch" />
                Isométrique {{
                  currentStepMinimalist.commentaire
                    && currentStepMinimalist.commentaire.seance_exercice
                    && !currentStepMinimalist.commentaire.longue
                    && currentStepMinimalist.longue ? '*' : ''
                  }}
              </template>
            </div>
          </div>
          <button
            class="next-step btn"
            @click="nextStep"
            :disabled="chrono > 0"
          >
            Suivant
          </button>
        </div>
        <div v-else>{{currentStepMinimalist.type}}</div>
      </div>
      <template v-else>
        <div class="steps-container">
          <ul>
            <li
              v-for="(groupStep, indexGroup) in currentSeance.steps"
              :key="groupStep.id"
              :id="`step-index-${indexGroup}`"
              class="group-step"
              :class="{
                  [groupStep.type]: true,
                  active: (
                    groupStep.steps && groupStep.steps.find((s, ) => s.id === currentStep.id, )
                    || groupStep.step && groupStep.step.id === currentStep.id
                    || groupStep.steps && groupStep.steps.find(
                      (s, ) => s.steps && s.steps.find((ss, ) => ss.id === currentStep.id, ),
                    )
                  ),
              }"
            >
              <div class="move-group-container">
                <div
                  class="move-group"
                >
                  <i
                    v-if="indexGroup !== 0"
                    class="fa fa-chevron-up"
                    @click="moveGroup(indexGroup, -1, )"
                  />
                  <i
                    v-if="indexGroup !== currentSeance.steps.length-1"
                    class="fa fa-chevron-down"
                    @click="moveGroup(indexGroup, 1, )"
                  />
                </div>
              </div>
              <StepGroup
                v-if="groupStep.type === 'exo'"
                :group-step="groupStep"
                :current-step="currentStep"
                :chrono="chrono"
                @next-step="nextStep"
                @skip-chrono="skipChrono(); currentStepIndex += 1;"
                @change="handleStepChange($event, )"
                @display-modal="displayModal($event, )"
                @edit-modifier="editModifier"
              />
              <div v-else-if="groupStep.type === 'superset'">
                <div class="superset-title">
                  Super-set de {{groupStep.nbSuperset}} exercices
                </div>
                <StepGroup
                  v-for="supersetStep in groupStep.steps"
                  :key="supersetStep.id"
                  :group-step="supersetStep"
                  :current-step="currentStep"
                  :chrono="chrono"
                  superset
                  @next-step="nextStep"
                  @skip-chrono="skipChrono(); currentStepIndex += 1;"
                  @change="handleStepChange($event, )"
                  @display-modal="displayModal($event, )"
                />
              </div>
              <div v-else>
                <template v-if="groupStep.type === 'warmup'">
                  <div class="exo-title">
                    Échauffement
                  </div>
                  <textarea
                    v-if="groupStep.step.id === currentStep.id"
                    :value="groupStep.step.commentaire.texte || groupStep.step.notes_echauffement"
                    placeholder="Non défini. Exemple : 10 minutes de rameur puis une série à vide."
                    rows="4"
                    @change="saveInfoEchauffement(groupStep, )"
                  />
                  <button
                    v-if="groupStep.step.id === currentStep.id && chrono === 0"
                    class="next-step btn"
                    @click="nextStep"
                  >
                    Suivant
                  </button>
                </template>
                <template v-else>
                  rip {{groupStep}}
                </template>
              </div>
              <div class="suppr-group">
                <ValidationButton
                  outline
                  @validated="supprGroup(indexGroup, )"
                >
                  <i class="fa fa-times" />
                </ValidationButton>
              </div>
            </li>
          </ul>
        </div>
      </template>
      <div
        class="chrono-options-container"
        :class="{ 'down': false, }"
      >
        <button
          v-if="!minimalistView && chrono > 0"
          id="chrono"
          :class="{'extended': chronoExtended, }"
          @click="chronoExtended = !chronoExtended"
        >
          {{chrono}}
        </button>
        <MenuDropdown
          id="options"
          buttonText="Options"
          :options="contextualMenu"
          placement="right"
        />
      </div>
    </div>
    <transition
      name="slide-left"
    >
      <ModalExoEntrainement
        v-if="modalIsDisplayed"
        :step="modalStep"
        @close="modalIsDisplayed = false"
      />
    </transition>
  </div>
</template>

<script>
import StartEntrainement from "@/components/entrainement/StartEntrainement.vue";
import EndEntrainement from "@/components/entrainement/EndEntrainement.vue";
import ResumeEntrainement from "@/components/entrainement/ResumeEntrainement.vue";
import AddExoEntrainement from "@/components/entrainement/AddExoEntrainement.vue";
import ModalExoEntrainement from "@/components/entrainement/ModalExoEntrainement.vue";
import ValidationButton from "@/components/ValidationButton.vue";
import StepGroup from "@/components/StepGroup.vue";
import MenuDropdown from "@/components/MenuDropdown.vue";

import Api from "@/modules/axios";
import {
  catchError, moveInArray, parseSeance, trimDecimalsIfNeeded,
} from "@/utils";

import { mapGetters } from "vuex";

export default {
  name: "Entrainement",
  components: {
    StartEntrainement,
    EndEntrainement,
    ModalExoEntrainement,
    ValidationButton,
    ResumeEntrainement,
    AddExoEntrainement,
    StepGroup,
    MenuDropdown,
  },
  data() {
    return {
      mountedSaveSeance: {},

      loaded: false,
      currentSeance: null,
      currentStepIndex: 0,

      chrono: 0,
      chronoEndingDate: null,

      modalIsDisplayed: false,
      modalStep: null,

      isAddingExo: false,

      optionsDisplayed: false,
      internaleEnableBeep: JSON.parse(localStorage.getItem("enableBeep")),
      internaleMinimalistView: JSON.parse(localStorage.getItem("minimalistView")),
    };
  },
  watch: {
    currentStepIndex() {
      this.saveLocalStorage();
      this.handleAvatarText();
    },
    chronoEndingDate() {
      this.saveLocalStorage();
    },
    currentSeance: {
      deep: true,
      handler() {
        if (!this.currentSeance) {
          return null;
        }

        let ordre = 0;
        this.currentSeance.steps.forEach((s) => {
          if (s.steps) {
            s.steps.forEach((s2) => {
              if (s2.steps) {
                s2.steps.forEach((s3) => {
                  this.$set(s3, "ordre", ordre);
                  ordre += 1;
                });
              } else {
                this.$set(s2, "ordre", ordre);
                ordre += 1;
              }
            });
          }
        });

        this.saveLocalStorage();
        return true;
      },
    },
    chrono() {
      this.saveLocalStorage();
    },
  },
  computed: {
    ...mapGetters(["user", "token"]),
    contextualMenu() {
      return [
        {
          icon: "fa-arrow-left",
          title: "Revenir à la série précedente",
          handler: this.goBackSerie,
          validation: true,
          disabled: () => this.currentStepIndex === 0,
        },
        {
          icon: "fa-add",
          title: "Ajouter un exercice",
          handler: () => { this.isAddingExo = true; },
        },
        {
          icon: "fa-triangle-exclamation",
          title: "Abandonner la séance en cours",
          handler: this.giveupSeance,
          validation: true,
        },
        {
          icon: "fa-stopwatch",
          title: `${this.enableBeep ? "Désactiver" : "Activer"} le signal sonore`,
          handler: () => { this.enableBeep = !this.enableBeep; },
          noClose: true,
        },
      ];
    },
    currentStep() {
      return this.stepsFlatten[this.currentStepIndex];
    },
    currentNextStep() {
      return this.stepsFlatten[this.currentStepIndex + 1];
    },
    currentStepMinimalist() {
      if (this.chrono > 0) {
        return this.currentNextStep;
      } else {
        return this.currentStep;
      }
    },
    enableBeep: {
      get() {
        return this.internaleEnableBeep;
      },
      set(val) {
        this.internaleEnableBeep = val;
        localStorage.setItem("enableBeep", JSON.stringify(val));
      },
    },
    minimalistView: {
      get() {
        return this.internaleMinimalistView;
      },
      set(val) {
        this.internaleMinimalistView = val;
        localStorage.setItem("minimalistView", JSON.stringify(val));
      },
    },
    isRepos() {
      return this.chrono > 0;
    },
    isResume() {
      return !!this.mountedSaveSeance.currentSeance && this.mountedSaveSeance.currentSeance.nom;
    },
    stepsFlatten() {
      return this.currentSeance.steps.map((s) => {
        if (s.type === "exo") {
          return s.steps;
        } else if (s.type === "superset") {
          return s.steps.map((s2) => s2.steps).flat();
        } else {
          return s.step;
        }
      }).flat();
    },
    degressif() {
      return (this.currentStep.commentaire && this.currentStep.commentaire.seance_exercice
        ? this.currentStep.commentaire.degressif
        : this.currentStep.degressif);
    },
    longue() {
      return (this.currentStep.commentaire && this.currentStep.commentaire.seance_exercice
        ? this.currentStep.commentaire.longue
        : this.currentStep.longue);
    },
  },
  mounted() {
    if (!this.user.beta_accepted) {
      alert("Vous n'êtes pas autorisé à accéder à cette page");
      return this.$router.push({ name: "accueil" });
    } else {
      this.handleAvatarText();
      this.mountedSaveSeance = JSON.parse(localStorage.getItem("save") || "{}");
      return true;
    }
  },
  methods: {
    trimDecimalsIfNeeded,
    startSeance(seance) {
      this.currentSeance = parseSeance(seance, true);
      this.currentStepIndex = 0;
      this.handleAvatarText();
      this.scrollToCurrentGroup();

      Api().post("/log/", { seance: this.currentSeance.id })
        .then((res) => {
          this.$set(this.currentSeance, "logId", res.data.id);
        });
    },
    nextStep(e) {
      if (this.currentStep.type === "warmup") {
        this.currentStepIndex += 1;
        return true;
      }

      e.target.disabled = true;
      return Api({ queue: true }).post(
        "/performance/",
        {
          exercice: this.currentStep.exercice.id,
          seance_exercice: this.currentStep.added ? null : this.currentStep.id,
          poids: this.currentStep.poids,
          nb_repetition: this.currentStep.reps,
          serie: this.currentStep.serie,
        },
        true,
      )
        .catch(catchError)
        .then((res) => {
          if (res.data) {
            this.$set(this.currentStep, "perfId", res.data.id);
          }

          e.target.disabled = false;
          if (this.currentStep.duree_repos && this.currentStep.id !== this.stepsFlatten.at(-1).id) {
            this.startChrono();
          } else {
            this.currentStepIndex += 1;
            this.scrollToCurrentGroup();
          }
        })
        .catch(() => {
          e.target.disabled = false;
        });
    },
    saveInfoEchauffement(groupStep) {
      const data = {
        texte: groupStep.step.notes_echauffement,
      };

      let promise = Promise.resolve();

      if (groupStep.step.commentaire && groupStep.step.commentaire.id) {
        promise = Api().patch(`/entrainement_commentaire/${groupStep.step.commentaire.id}/`, data);
      } else {
        promise = Api().post("/entrainement_commentaire/", {
          ...data,
          seance_exercice: groupStep.step.id,
        });
      }

      promise.then((res) => {
        this.$set(groupStep.step, "commentaire", res.data);
        this.$toast("Note d'échauffement enregistré");
      });
    },
    editModifier([step, modifier]) {
      const data = {
        [modifier]: step.commentaire ? !step.commentaire[modifier] : !step[modifier],
      };

      let promise = Promise.resolve();

      if (step.commentaire && step.commentaire.id) {
        promise = Api().patch(`/entrainement_commentaire/${step.commentaire.id}/`, data);
      } else {
        promise = Api().post("/entrainement_commentaire/", {
          ...data,
          seance_exercice: step.id,
        });
      }

      this.$set(step.commentaire, modifier, "loading");
      promise
        .then((res) => {
          this.$set(step, "commentaire", res.data);
        })
        .catch(catchError);
    },
    resetBack() {
      const seance = this.currentSeance || this.mountedSaveSeance.currentSeance;
      if (seance.logId) {
        Api().patch(`/log/${seance.logId}/`, { ended: true });
      }
      this.chronoEndingDate = null;
      this.currentSeance = null;
      this.currentStepIndex = 0;
      this.chrono = 0;
      localStorage.removeItem("save");
    },
    startChrono(chronoInitial) {
      if (!chronoInitial) {
        this.chronoEndingDate = new Date();
        this.chronoEndingDate.setSeconds(
          this.chronoEndingDate.getSeconds() + (
            parseInt(this.currentStep.duree_repos, 10)
          ),
        );
      }

      const chronoEndCallback = () => {
        clearInterval(this.chronoInterval);
        this.chronoExtended = false;
        this.chronoEndingDate = null;
        this.chrono = 0;
        this.currentStepIndex += 1;

        if (this.enableBeep) {
          try {
            const snd = new Audio("/beep.mp3");
            snd.volume = 0.1;
            snd.play();
          } catch (error) {
            console.log(error);
          }
        }

        this.scrollToCurrentGroup();
      };

      this.chrono = this.getCurrentChrono();
      // Evite d'afficher une valeur négative pendant une seconde
      if (this.chrono <= 0 || !this.chrono) {
        return chronoEndCallback();
      }

      this.chronoExtended = true;
      clearInterval(this.chronoInterval);
      this.chronoInterval = setInterval(() => {
        this.chrono = this.getCurrentChrono();
        if (this.chrono <= 0) {
          chronoEndCallback();
        }
      }, 1000);
      this.handleAvatarText();
      return true;
    },
    getCurrentChrono() {
      const dif = this.chronoEndingDate.getTime() - new Date().getTime();
      const seconds = dif / 1000;

      return Math.trunc(seconds);
    },
    skipChrono() {
      clearInterval(this.chronoInterval);
      this.chrono = 0;
      this.chronoEndingDate = null;
      this.scrollToCurrentGroup();
    },
    handleWelcomeMessage() {
      const messagesTypes = [
        "prog_jour_remis_last_seance",
        "seance_jour",
      ];

      if (this.selectedStartSeance) {
        messagesTypes.push("hint_seance");
      }

      const randomItem = messagesTypes[Math.floor(Math.random() * messagesTypes.length)];
      switch (randomItem) {
        case "seance_jour":
          this.$store.commit("setAvatarText", `
            <b>Salut ${this.user.first_name} !</b><br/><br/>
            Quelle séance pour aujourd'hui&nbsp;?
          `);
          break;
        case "prog_jour_remis_last_seance":
          // seance_exercice__isnull=false ne fonctionne pas?
          Api().get("/performance/?ordering=-date_creation&seance_exercice__isnull=false")
            .then((res) => {
              if (!res.data.results.length) {
                this.$store.commit("setAvatarText", `
                  <b>Salut ${this.user.first_name} !</b><br />
                  Quel est le programme du jour&nbsp;?<br /><br />
                  J'espère que tu t'es bien remis de la dernière séance.
                `);
              } else if (
                res.data.results[0]
                && res.data.results[0].seance_exercice
                && res.data.results[0].seance_exercice.seance
              ) {
                this.$store.commit("setAvatarText", `
                  <b>Salut ${this.user.first_name} !</b><br />
                  Quel est le programme du jour&nbsp;?<br /><br />
                  J'espère que tu t'es bien remis de la séance "${res.data.results[0].seance_exercice.seance.nom}".
                `);
              }
            });
          break;
        case "hint_seance":
          this.$store.commit("setAvatarText", `
            <b>Salut ${this.user.first_name} !</b><br /><br/>
            Prêt pour la séance "${this.seances[this.selectedStartSeance].nom}"&nbsp?
          `);
          break;
        default:
          break;
      }
    },
    handleAvatarText() {
      this.$store.commit("setAvatarText", "");
      if (this.isResume) {
        // this.$store.dispatch("faceExpression", "reset");
        // this.$store.commit("setAvatarText", "Ahhh ! Vous revoilà !");
      } else if (!this.currentSeance) {
        this.$store.dispatch("faceExpression", "thinking");
        this.handleWelcomeMessage();
      } else if (!this.currentStep) {
        this.$store.dispatch("faceExpression", "very-happy");
        this.$store.commit("setAvatarText", `
          Très bonne séance ! Vous avez mérité de vous reposer maintenant.
        `);
      } else if (this.isRepos) {
        this.$store.dispatch("faceExpression", "happy");
        this.$store.commit("setAvatarText", "N'oubliez pas de vous hydrater&nbsp;!");
      } else {
        this.$store.dispatch("faceExpression", "look-up-left");
        this.$store.commit(
          "setAvatarText",
          "Essayez d'expirer pendant l'effort et d'inspirer sur le relâcher",
        );
      }
    },
    displayModal(step) {
      this.modalIsDisplayed = true;
      this.modalStep = step;
    },
    goBackSerie() {
      if (this.currentStepIndex - 1 >= 0) {
        this.currentStepIndex -= 1;
      } else {
        this.$toast("Aucune série précédente", true);
      }
    },
    giveupSeance() {
      this.resetBack();
      this.mountedSaveSeance = {};
      localStorage.removeItem("save");
      if (this.chronoInterval) {
        clearInterval(this.chronoInterval);
      }
      this.handleAvatarText();
    },
    saveLocalStorage() {
      localStorage.setItem("save", JSON.stringify({
        chronoEndingDate: this.chronoEndingDate,
        currentSeance: this.currentSeance,
        currentStepIndex: this.currentStepIndex,
        chrono: this.chrono,
      }));
    },
    resumeSeance() {
      this.chronoEndingDate = new Date(this.mountedSaveSeance.chronoEndingDate);
      this.currentSeance = this.mountedSaveSeance.currentSeance;
      this.currentStepIndex = this.mountedSaveSeance.currentStepIndex;
      this.chrono = this.mountedSaveSeance.chrono;

      if (this.chrono) {
        this.startChrono(this.chrono);
      }

      this.mountedSaveSeance = {};
      this.scrollToCurrentGroup();
    },
    scrollToCurrentGroup() {
      return this.$nextTick(() => {
        if (!this.currentStep) {
          return false;
        }
        const currentGroupIndex = this.currentSeance.steps.findIndex(
          (gs) => {
            if (gs.type === "warmup") {
              return gs.step.id === this.currentStep.id;
            } else if (gs.type === "superset") {
              return !!gs.steps.find((s) => s.steps.find((ss) => ss.id === this.currentStep.id));
            } else {
              return !!gs.steps.find((s) => s.id === this.currentStep.id);
            }
          },
        );

        const stepElement = document.querySelector(`#step-index-${currentGroupIndex}`);

        if (stepElement) {
          return stepElement.scrollIntoView({ behavior: "smooth", block: "center" });
        } else {
          return false;
        }
      });
    },
    addExo({ exo, nbSerie, repos }) {
      if (!exo || !nbSerie) {
        return this.$toast("Veuillez séléctionner un exercice", true);
      }

      // Créer un tableau avec [1,2,3,4...] (commençant à 1)
      const series = [...Array(nbSerie).keys()].map((i) => i + 1);

      return Api().get(`/exercice/${exo.id}/last_perf/?series=${series}`)
        .then((res) => {
          const currentGroupIndex = this.currentSeance.steps.findIndex(
            (gs) => {
              const currentStepId = this.currentStep ? this.currentStep.id : null;
              if (gs.step) {
                return gs.step.id === currentStepId;
              } else {
                return !!gs.steps.find((s) => s.id === currentStepId);
              }
            },
          );

          const tmpStep = [];
          series.forEach((serie) => {
            const seriePerf = res.data.find((p) => parseInt(p.serie, 10) === serie);
            tmpStep.push(
              {
                id: `${exo.id}add${serie}${Math.random()}`,
                added: true,
                serie,
                exercice: exo,
                ordre: this.currentStep ? this.currentStep.ordre : 1,
                duree_repos: repos,
                type: "exo",
                poids: trimDecimalsIfNeeded(seriePerf.last.poids),
                reps: seriePerf.last.reps,
                last_perfs: seriePerf,
              },
            );
          });

          this.currentSeance.steps.splice(
            // + serie-1 : permet d'ajouter à la suite et non en premier à chaque fois
            currentGroupIndex + 1,
            0, // Pas de delete donc insert
            {
              nom: exo.nom,
              type: "exo",
              steps: tmpStep,
            },
          );

          this.isAddingExo = false;
          this.$set(this.currentSeance, "steps", this.currentSeance.steps);

          this.$nextTick(() => {
            document.querySelector(`#step-index-${currentGroupIndex + 1}`).scrollIntoView(
              { behavior: "smooth", block: "center" },
            );
          });
        });
    },
    moveGroup(indexGroup, delta) {
      moveInArray(this.currentSeance.steps, indexGroup, indexGroup + delta);
      this.$set(this.currentSeance, "steps", this.currentSeance.steps);
      this.saveLocalStorage();
    },
    supprGroup(groupIndex) {
      this.currentSeance.steps.splice(groupIndex, 1);
      this.$set(this.currentSeance, "steps", this.currentSeance.steps);
      this.saveLocalStorage();
    },
    handleStepChange(step) {
      const realStep = this.stepsFlatten.find((se) => se.id === step.id);
      this.$set(realStep, "poids", step.poids);
      this.$set(realStep, "reps", step.reps);

      if (step.perfId || realStep.perfId) {
        return Api().patch(
          `/performance/${step.perfId || realStep.perfId}/`,
          {
            poids: realStep.poids,
            nb_repetition: realStep.reps,
          },
          true,
        )
          .then(() => {
            this.$toast("Modification enregistrée");
          })
          .catch(catchError);
      }

      return false;
    },
    toggleOptions() {
      this.optionsDisplayed = !this.optionsDisplayed;

      this.$nextTick(() => {
        if (this.optionsDisplayed) {
          document.querySelector(".contextual-menu").scrollIntoView({ behavior: "smooth" });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/variables.scss" as var;

.page-entrainement {
  .steps-container {
    margin-top: 2rem;
    position: relative;
    scroll-behavior: smooth;
    > ul {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }

  .group-step {
    // border-radius: var.$border-radius;
    border-bottom: 1px solid var.$border-gray;
    position: relative;
    padding: 1rem;
    text-align: center;

    display: flex;
    &:not(.active) {
      opacity: 0.4;
    }
  }

  .steps {
    display: flex;
    flex-direction: column;
    // gap: 1rem;
  }

  .step {
    &.no-repos {
      padding-bottom: 1.5rem;
    }
    &.active {
      margin: 1rem 0;
      &:not(.chrono-running) {
        .repos {
          opacity: 0.4;
        }
      }
    }
    // &:not(.active) {
    //   opacity: 0.4;
    // }
    .step-inputs {
      display: flex;
      gap: 2rem;
      justify-content: center;
      align-items: center;
    }
    input {
      border: 1px solid var.$border-gray;
      border-radius: var.$input-border-radius;
      box-shadow: none;
      color: black;
      width: 4rem;

      // flèches du number
      -moz-appearance: textfield;
      &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      text-align: center;
      font-size: 1em;
      padding: 2px .5rem;
      margin-right: 0.2rem;
    }
  }

  .exo-title {
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    .fa-link {
      opacity: 0.5;
    }
    .fa-info-circle {
      cursor: pointer;
    }
  }

  .group-step.superset .exo-title{
    font-weight: normal;
    font-size: 1.1rem;
  }
  .superset-title {
    font-weight: bold;
    font-size: 1.2rem;
  }

  .repos {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 3rem;
    &::before, &::after {
      content: "";
      height: 1px;
      flex: 1;
      background-color: rgba(var.$red, 0.3);
      margin: 0 1rem;
    }
  }
  .warmup {
    textarea {
      width: 90%;
      display: block;
      margin: 1rem auto;
    }
  }
  .next-step {
    width: 55%;
  }
  .chrono-running {
    .step-inputs {
      opacity: 0.4;
    }
    .repos {
      opacity: 1;
    }
  }
  .skip-repos {
    display: block;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 1rem;
  }
}
.options-button-container {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.contextual-menu {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 1rem;
  button {
    width: 7.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    margin: 0.3rem;
    i {
      margin-bottom: 0.2rem;
    }
  }
  .button-container {
    margin: 0;
  }
}

.chrono-options-container {
  position: sticky;
  bottom: 0.5rem;
  left: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  z-index: 9;
  margin-top: 1rem;
  margin-bottom: -1rem;
}
#options .v-popover .trigger {
  z-index: 9;
  position: fixed;
  bottom: 1rem;
  left: 1rem;
}
#chrono {
  width: 4rem;
  height: 4rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  border: 1px solid rgba(var.$transparent-dark, 0.1);
  overflow: hidden;
  background: linear-gradient(180deg, rgba(white, 0.70) 0%, white 50%);

  font-size: 2rem;
  margin-top: 2rem;
  z-index: 10;
  &.extended {
    position: fixed;
    background: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 2rem);
    height: 60vh;
    padding: 7rem 0;
    background: var.$transparent-dark;
    color: white;
    border-radius: var.$border-radius;
    font-size: 15rem;
    margin-top: 0;
  }
}

.move-group-container {
  width: 2rem;
}

.group-step {
  // Colonnes

  .move-group, .suppr-group { // 1 et 3
    width: 2rem;
  }
  > div:nth-child(2) { // 2
    flex: 1;
  }
}

.move-group {
  display: flex;
  flex-direction: column;
  .fa-chevron-up {
    margin-bottom: 1.5rem;
  }
}
.suppr-group .button-container {
  margin-top: 0;
  border: 0;
  .btn {
    border: none;
    padding: 0rem 1rem;
    cursor: pointer;
  }
}

</style>
