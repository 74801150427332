<template>
  <v-popover
    popoverClass="menu-dropdown"
    ref="drop"
    :placement="placement || 'bottom'"
  >
    <!-- This will be the popover target (for the events and position) -->
    <button
      class="menu-toggle"
      @click.prevent.stop="$refs.drop.isOpen ? $refs.drop.hide() : $refs.drop.show()"
    >
      <i class="fa fa-ellipsis-vertical" />
      <span v-if="buttonText">
        {{buttonText}}
      </span>
    </button>

    <!-- This will be the content of the popover -->
    <template
      slot="popover"
    >
      <ul>
        <li
          v-for="opt in filteredOptions"
          :key="opt.title"

        >
          <button
            @click="opt.handler && opt.handler(); !opt.noClose && $refs.drop.hide();"
            :disabled="opt.disabled && opt.disabled()"
          >
            <i
              v-if="opt.icon"
              class="fa"
              :class="opt.icon"
            />
            <span>
              {{opt.title}}
            </span>
          </button>
        </li>
      </ul>
    </template>
  </v-popover>
</template>

<script>
export default {
  name: "MenuDropdown",
  props: {
    options: Array,
    buttonText: String,
    placement: {
      type: String,
      default: "bottom",
    },
  },
  computed: {
    filteredOptions() {
      return this.options.filter((o) => o);
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/variables.scss" as var;

.menu-toggle {
  border-radius: var.$border-radius;
  border: 1px solid var.$border-gray;
  padding: 0.5rem;
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  span {
    margin-left: 0.5rem;
    font-size: 1rem;
  }
}

.menu-dropdown.tooltip {
  // max-width: 90vw;
  .popover-arrow {
    border-color: var.$gray-dark;
  }
  .popover-inner {
    padding: 0;
    background: white;
    border: 1px solid var.$gray-dark;
    overflow: hidden;
    ul {
      li {
        button {
          background: none;
          border: none;
          width: 100%;
          background: white;
          color: black;
          padding: 1rem;
          cursor: pointer;
          // font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          &:disabled {
            color: var.$border-gray;
            cursor: not-allowed;
          }
          &:hover {
            background: white;
          }
        }
        & + li {
          border-top: 1px solid var.$border-gray;
        }

      }
    }
    .fa {
      margin-right: 1rem;
      width: 2rem;
      text-align: center;
    }
  }

  .fa-square-xmark {
    font-size: 1.5rem;
    color: var.$red;
  }
  .fa-square-check {
    font-size: 1.5rem;
    color: green;
  }
  .fa-add {
    color: black;
  }

}
</style>
