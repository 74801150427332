<template>
  <div
    v-if="uuid"
    class="smart-watch-code"
  >
    <p>Scannez ce QRCode sur votre téléphone depuis votre profil afin d'autoriser cet appareil.</p>
    <VueQrcode
      :text="`smartwatch-${uuid}`"
      :correctLevel="3"
    />
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import VueQrcode from "vue-qr";

export default {
  name: "SmartWatchCode",
  components: {
    VueQrcode,
  },
  data() {
    return {
      uuid: null,
      interval: null,
    };
  },
  mounted() {
    this.uuid = localStorage.getItem("uuidSw");
    if (!this.uuid) {
      this.uuid = uuidv4();
      localStorage.setItem("uuidSw", this.uuid);
    } else {
      // Si le premier login ne fonctionne pas, retenter toutes les 5s
      this.$store.dispatch("login", { sw_uuid_allowed: this.uuid })
        .then((res) => {
          if (res === undefined) {
            this.interval = setInterval(() => {
              this.$store.dispatch("login", { sw_uuid_allowed: this.uuid })
                .then(() => {
                  if (res) {
                    clearInterval(this.interval);
                  }
                });
            }, 5000);
          }
        });
    }
  },
};
</script>

<style lang="scss">
.smart-watch-code {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    color: black !important;
    text-align: center;
  }
  img {
    width: 60vh;
  }
}
</style>
