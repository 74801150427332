import Vue from "vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import vSelect from "vue-select";
import VueToast from "vue-toast-notification";
import "vue-select/dist/vue-select.css";
import VTooltip from "v-tooltip";
import { sync } from "vuex-router-sync";

import store from "./store";
import router from "./router";
import App from "./App.vue";
import config from "./config";

// Import one of the available themes
import "vue-toast-notification/dist/theme-default.css";

import "./registerServiceWorker";

Vue.use(VueToast);
Vue.use(VTooltip);

sync(store, router);

if (window.location.hostname.includes("muscu")) {
  Sentry.init({
    Vue,
    dsn: "https://03accfcf45994e498d14cbe318c65d50@o786509.ingest.sentry.io/6469040",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["*"],
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

Vue.config.productionTip = false;
Vue.component("PulseLoader", PulseLoader);

Vue.directive("click-outside", {
  bind: (el, binding, vnode) => {
    el.clickOutsideEvent = (event) => {
      // here I check that click was outside the el and his children
      // console.log(event.target, event.target.parentElement);
      if (
        (
          !(el === event.target || el.contains(event.target))
          && event.target.id !== "menu_toggle"
          && event.target.parentElement && event.target.parentElement.id !== "menu_toggle"
        )
        || (event.target.parentElement && event.target.parentElement.classList.contains("item-menu"))
        || (event.target.parentElement && event.target.parentElement.tagName === "A")
        || (event.target.parentElement && event.target.parentElement.id === "contextual_menu")
        || event.target.id === "profile_link"
      ) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind: (el) => {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

const moment = require("moment");
require("moment/locale/fr");

const MyPlugin = {
  install(VueInstance) {
    VueInstance.prototype.config = config;
    VueInstance.prototype.$toast = (text, isError, duration) => {
      store.dispatch("displayToast", { text, isError, duration });
    };
  },
};

Vue.use(MyPlugin);

Vue.use(require("vue-moment"), {
  moment,
});

Vue.component("v-select", vSelect);

new Vue({
  name: "Main",
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
