<template>
  <VuePagination
    v-model="computedPage"
    :records="totalResults"
    :per-page="perPage"
    :options="pagOption"
    @paginate="$emit('paginate', $event, )"
  />
</template>

<script>
import VuePagination from "vue-pagination-2";

export default {
  name: "Pagination",
  components: {
    VuePagination,
  },
  model: {
    prop: "page",
    event: "paginate",
  },
  props: {
    page: Number,
    totalResults: Number,
    perPage: {
      type: Number,
      default: 15,
    },
    displayOnePageText: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  computed: {
    pagOption() {
      const options = {
        chunk: 5,
        chunksNavigation: "fixed",
        texts: {
          count: "",
        },
      };

      options.texts.count = (
        this.displayOnePageText
          ? "Éléments {from} à {to} sur {count} au total.|{count} résultats.|Un seul résultat."
          : "Éléments {from} à {to} sur {count} au total.||"
      );

      return options;
    },
    computedPage: {
      get() {
        return this.page;
      },
      set(val) {
        this.$emit("paginate", val);
      },
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/variables.scss" as var;

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  gap: 0.5rem;
  li {
    padding: 0.7rem 1rem;
    border-radius: 0.3rem;
    &:not(.active, .disabled){
      cursor: pointer;
      &:hover {
        background: var.$border-gray;
      }
    }
    &.active {
      background: var.$red;
      a {
        color: white;
      }
      &:hover {
        background: var.$red-darker;
      }
    }
  }
}
.VuePagination__count {
  text-align: center;
  font-style: italic;
}
.VuePagination__pagination-item {
  // display: none;
  &.disabled {
    opacity: 0.3;
  }
}
</style>
