/* eslint-disable no-await-in-loop */
import store from "@/store";
import router from "@/router";

function catchError(err) {
  if (err.code === "ERR_CANCELED") {
    return Promise.resolve({ data: { queued: true } });
  } else if (err.response) {
    if (typeof err.response.data === "object") {
      const text = Object.entries(err.response.data).map(
        ([key, val]) => (key === "error" ? val : `${key}: ${val}`),
      )[0]; // .join("<br/>");
      store.dispatch("displayToast", { text, isError: true });
    } else {
      store.dispatch("displayToast", { text: JSON.stringify(err.response.data), isError: true });
    }
  } else {
    store.dispatch("displayToast", { text: err.error || err.message, isError: true });
  }
  return Promise.reject(err);
}

const moveInArray = (array, from, to) => {
  array.splice(to, 0, array.splice(from, 1)[0]);
};

const trimDecimalsIfNeeded = (number) => {
  try {
    return number.endsWith(".00") ? number.split(".")[0] : number;
  } catch (e) {
    return number;
  }
};

const interleave = (arr) => Array.from(
  {
    length: Math.max(...arr.map((o) => o.length)), // find the maximum length
  },
  (_, i) => arr.map((r) => r[i] ?? null), // create a new row from all items in same column or substitute with null
).flat();

const parseSeance = (seance, modeEntrainement) => {
  const copy = { ...seance };
  let steps = [];

  let tmpExo = null;
  let tmpStep = {
    nom: null,
    type: "exo",
    steps: [],
  };

  copy.seance_exercices.sort((a, b) => a.ordre - b.ordre);
  copy.seance_exercices.forEach((se) => {
    // se.duree_repos = 15;

    if (se.last_perfs) {
      se.poids = se.last_perfs.last ? (
        trimDecimalsIfNeeded(se.last_perfs.last.poids)
      ) : null;

      se.reps = se.last_perfs.last ? (
        trimDecimalsIfNeeded(se.last_perfs.last.reps)
      ) : null;
    }

    // Groupement des exos
    if (se.type === "exo") {
      if (tmpExo !== se.exercice.nom) {
        // Changement d'exo
        if (tmpStep.nom) {
          // Premier tour de boucle, tmpStep est null
          steps.push(tmpStep);
        }
        tmpStep = {
          nom: se.exercice.nom,
          type: "exo",
          steps: [],
        };
      }
      tmpExo = se.exercice.nom;
      tmpStep.steps.push(se);

      // on le set à chaque fois car c'est le dernier SE qui compte
      tmpStep.superset = se.superset;
    } else if (se.type === "warmup") {
      if (tmpStep.nom) {
        // Premier tour de boucle, tmpStep est null
        steps.push(tmpStep);
        tmpStep = {};
      }
      steps.push({
        nom: "Échauffement",
        type: "warmup",
        step: se,
      });
    }
  });
  // fin du parsing

  if (tmpStep.nom) {
    steps.push(tmpStep);
  }

  // Gestion super-set
  if (modeEntrainement) {
    const groupedStep = [];

    let tmpSuperSet = [];
    let previousIsSuperSet = false;
    steps.forEach((s) => {
      if (s.superset) {
        tmpSuperSet.push(s);
      } else if (previousIsSuperSet) {
        s.supersetExo = tmpSuperSet;
        tmpSuperSet = [];

        groupedStep.push(s);
      } else {
        groupedStep.push(s);
      }
      previousIsSuperSet = s.superset;
    });

    const finalStep = [];

    groupedStep.forEach((s) => {
      if (s.supersetExo) {
        const mix = interleave([...s.supersetExo.map((se) => se.steps), s.steps]);

        const tmpMixSteps = [];
        mix.forEach((m) => {
          if (m) {
            tmpMixSteps.push({
              nom: m.exercice.nom,
              type: "exo",
              steps: [m],
              // nbSuperset: s.supersetExo.length + 1, // +1 car lui même compris
              // superset: true,
              // nbSupersetSteps: mix.length, // Utiliser pour le move
              // firstOfSuperset: index === 0,
            });
          }
        });
        finalStep.push({
          type: "superset",
          steps: tmpMixSteps,
          nbSuperset: s.supersetExo.length + 1,
        });
      } else {
        finalStep.push(s);
      }
    });
    steps = finalStep;
  }

  copy.steps = steps;
  return copy;
};

function getPWADisplayMode() {
  const isStandalone = window.matchMedia("(display-mode: standalone)").matches;
  if (document.referrer.startsWith("android-app://")) {
    return "twa";
  } else if (navigator.standalone || isStandalone) {
    return "standalone";
  }
  return "browser";
}

function fnBrowserDetect() {
  const userAgent = navigator.userAgent;
  let browserName;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = "chrome";
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = "firefox";
  } else if (userAgent.match(/safari/i)) {
    browserName = "safari";
  } else if (userAgent.match(/opr\//i)) {
    browserName = "opera";
  } else if (userAgent.match(/edg/i)) {
    browserName = "edge";
  } else {
    browserName = "No browser detection";
  }

  return browserName;
}

function waitForElm(selector) {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    return observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const AUTOPLAY = false;

const startTuto = async () => {
  router.push({ name: "accueil" });
  window.isTuto = true;
  const createTarget = (selector, container) => {
    const div = document.createElement("div");

    const parentPos = container.getBoundingClientRect();
    const childPos = document.querySelector(selector).getBoundingClientRect();
    const relativePos = {};

    relativePos.top = (childPos.top - parentPos.top) + container.scrollTop;
    relativePos.left = childPos.left - parentPos.left;

    const padding = 16;

    div.style.height = `${childPos.height + padding}px`;
    div.style.width = `${childPos.width + padding}px`;

    div.style.top = `${relativePos.top - (padding / 2)}px`;
    div.style.left = `${relativePos.left - (padding / 2)}px`;
    div.classList.add("tutorial-target");
    container.appendChild(div);
    return div;
  };

  const createText = (text, textPosition, clickElement, container) => {
    const div = document.createElement("div");
    div.innerHTML = text;
    div.classList.add("tutorial-text");
    if (textPosition === "top") {
      div.classList.add("top");
    }

    const button = document.createElement("button");
    button.innerText = "Suivant";
    div.appendChild(button);
    button.addEventListener("click", (e) => {
      e.stopPropagation();
      clickElement.click();
    });
    container.appendChild(div);
    return div;
  };

  const play = (step) => (
    new Promise((resolve) => {
      const container = document.querySelector(step.container || "#content");

      const selector = step.targetElement;
      const text = step.text;
      const elementClick = document.querySelector(step.clickElement || selector);
      const mask = createTarget(selector, container);
      let textElement = null;
      if (text) {
        textElement = createText(text, step.textPosition, mask, container);
      }

      mask.addEventListener("click", () => {
        elementClick.click();
        container.removeChild(mask);
        if (textElement) {
          container.removeChild(textElement);
        }
        if (step.afterClickCallback) {
          step.afterClickCallback();
        }
        resolve(selector);
      });

      if (AUTOPLAY) {
        setTimeout(() => {
          mask.click();
        }, 1000);
      }
    })
  );

  const elements = [
    {
      targetElement: "#menu_toggle",
      container: "body",
      text: "Bienvenue&nbsp;!<br/>"
        + "Commençons par découvrir les <strong>entrainements&nbsp;!</strong><br/><br/>"
        + "Ouvre le <strong>menu</strong> en haut à gauche.",
    },
    {
      targetElement: ".item-menu.entrainement a", // entrainement
      container: "body",
      text: "Rends-toi maintenant dans la section <strong>Entrainement</strong>.",
    },
    {
      // Premiere séance
      targetElement: "#seance_select > div:nth-child(3) > ul:nth-child(2) > li:nth-child(1)",
      text: "Lançons une <strong>séance</strong> de démonstration&nbsp;!",
      textPosition: "top",
    },
    {
      targetElement: "#minimal_toggle",
      text: "Ceci est l'interface miniamliste, une seule série est affichée à la fois.<br/>"
        + "Tu peux passer en mode détaillé pour avoir plus d'informations.",
    },
    {
      targetElement: ".warmup",
      clickElement: ".warmup .next-step",
      text: "L'<strong>échauffement</strong> est paramétrable par le créateur de la séance"
        + " ainsi que par toi-même afin de correspondre à ton envie.<br/><br/>"
        + "Clique sur <strong>Suivant</strong> pour valider"
        + " l'<strong>échauffement</strong>&nbsp;!",
    },
    {
      targetElement: "div.active:nth-child(2) > div:nth-child(1) >"
      + " span:nth-child(1) > i:nth-child(1)",
      text: "Cette icône permet de voir les <strong>informations de l'exercice</strong>.<br/><br/>"
        + "Clique dessus pour voir l'image&nbsp;!",
    },
    {
      targetElement: ".modal-exo .personal-comment",
      text: "N'hésite pas à renseigner les réglages des machines ou tout autre commentaire ici.",
    },
    {
      targetElement: ".modal-exo .fa-times",
      text: "Retournons à la <strong>séance</strong>.",
    },
    {
      targetElement: ".step.active .step-inputs",
      text: "Ici, tu peux renseigner le <strong>poids</strong> de la série ainsi que"
      + " le <strong>nombre de répétition</strong> réalisées"
        + " (un nombre indicatif est déjà prévu par son créateur).",
      afterClickCallback: () => {
        document.querySelector(".step.active .kg").value = "20";
        document.querySelector(".step.active .kg").dispatchEvent(new Event("input"));
      },
    },
    {
      targetElement: ".step.active .next-step",
      text: "Passons à la <strong>série suivante</strong>.",
    },
    {
      targetElement: "#chrono",
      text: "Ce chronomètre montre ton <strong>temps de repos</strong> entre chaque"
        + " <strong>série</strong>.<br/>Clique dessus pour le réduire.",
    },
    {
      targetElement: ".step.active .skip-repos",
      text: "Pas le temps&nbsp;! Passons à la <strong>série suivante</strong>&nbsp;!",
    },
    {
      targetElement: ".step.active .modifiers",
      text: "Il est possible de définir des <strong>modificateurs</strong> sur certaines"
        + " <strong>séries</strong>.<br />"
        + "Par exemple, en <strong>isométrie</strong>, la charge est maintenue pendant quelques secondes"
        + " à la fin de la phase de contraction.",
      afterClickCallback: () => {
        document.querySelector(".step.active .kg").value = "30";
        document.querySelector(".step.active .kg").dispatchEvent(new Event("input"));
      },
    },
    {
      targetElement: ".step.active .next-step",
      text: "Passons à l'<strong>exercice suivant</strong>.",
      afterClickCallback: () => {
        setTimeout(() => {
          document.querySelector("#chrono").click();
          document.querySelector(".step.active .skip-repos").click();
        }, 350);
      },
    },
    {
      targetElement: ".group-step.active .superset-title",
      text: "Un <strong>superset</strong> signifie que plusieurs exercices sont réalisés"
        + " <strong>en alternance</strong>, avec ou sans repos entre chaque chacun.",
    },
    {
      targetElement: ".step.active .fa-times",
      text: "Tu peux <strong>supprimer</strong> une série, ou un exercice, ou un superset complet"
        + " si tu le souhaites."
        + "<br /> Pas de panique, en cas d'erreur, tu peux toujours en <strong>rajouter</strong>.",
    },
    {
      targetElement: ".group-step.active .move-group",
      text: "Tu peux aussi <strong>déplacer des exercices</strong> si une machine est occupée par exemple.",
    },
    {
      targetElement: ".menu-toggle",
      text: "Ce bouton te permet de réaliser des <strong>actions supplémentaires</strong> lors de ta séance.",
      textPosition: "top",
    },
    {
      targetElement: ".tooltip-inner > div:nth-child(1) > ul:nth-child(1) > li:nth-child(3) button",
      text: "Clique ici pour <strong>finir la séance</strong>.",
      textPosition: "top",
    },
    {
      targetElement: "#menu_toggle",
      text: "Nous allons maintenant voir la <strong>création d'une séance</strong>.<br />"
        + "Ouvre le <strong>menu</strong> en haut à gauche&nbsp;!",
    },
    {
      targetElement: ".item-menu.seances a", // entrainement
      text: "Rends toi maintenant dans la section <strong>Séances</strong>.",
    },
    {
      targetElement: ".create-seance",
      text: "Crée une <strong>nouvelle séance</strong>.",
      textPosition: "top",
    },
    {
      targetElement: "form",
      text: "Ici tu peux saisir les <strong>informations de ta séance</strong>.<br />"
      + "Pour cette fois, je vais le faire pour toi&nbsp;!",
      afterClickCallback: () => {
        document.querySelector("input.nom").value = "1. Ma super séance bras";
        document.querySelector("input.nom").dispatchEvent(new Event("input"));

        document.querySelector(".icon-toggle").click();
        setTimeout(() => {
          document.querySelector(".icons-list > img:nth-child(2)").click();

          document.querySelector("textarea.field").value = "Attention, ça va piquer !";
          document.querySelector("textarea.field").dispatchEvent(new Event("input"));
        }, 100);
      },
    },
    {
      targetElement: ".btns-add > button:nth-child(1)",
      text: "À ton tour, commence par <strong>ajouter un échauffement</strong>.",
      textPosition: "top",
    },
    {
      targetElement: ".btns-add > button:nth-child(2)",
      text: "Puis un <strong>exercice</strong>.",
      textPosition: "top",
    },
    {
      targetElement: "div.tile:nth-child(2)",
      text: "Celui là a l'air pas mal&nbsp;!",
    },
    {
      targetElement: "i.fa:nth-child(3)",
      text: "Une série de <strong>10 répétitions</strong> avec"
        + " <strong>90 secondes de repos</strong>, ça fait peu... <strong>Ajoute une série</strong>.",
    },
    {
      targetElement: "#step-index-1 .exo:nth-of-type(2) .modifier-item:nth-of-type(1)",
      text: "On va la faire en <strong>dégressif</strong> celle là.",
    },
    {
      targetElement: ".see-body-container .btn",
      text: "Tu peux voir les <strong>muscles travaillés</strong> par ta séance.",
    },
    {
      targetElement: ".modal-exo .fa-times",
      text: "Retournons à la <strong>séance</strong>.",
    },
    {
      targetElement: ".button-container > button:nth-child(1)",
      text: "<strong>Enregistre</strong> ta super séance",
      textPosition: "top",
      afterClickCallback: () => {
        document.querySelector(".blue-link").click();
      },
    },
    {
      targetElement: ".seances > li:nth-child(1) > a:nth-child(1)",
      text: "Et voila&nbsp;! Ta <strong>séance</strong> est créée, tu pourras la lancer quand"
        + " tu te sentira prêt&nbsp;!",
      clickElement: "body",
    },
  ];
  // On peut pas utiliser forEach avec les await
  for (let i = 0; i < elements.length; i += 1) {
    if (i === elements.length - 2) {
      // permet d'afficher toutes les séances à la fin
      window.isTuto = false;
    }
    const currentElement = elements[i];

    await waitForElm(currentElement.targetElement);
    const element = document.querySelector(currentElement.targetElement);
    element.scrollIntoView({ behavior: "smooth", block: "center" });
    await sleep(400);

    await play(currentElement);
    await sleep(600);
  }
};

export {
  catchError, moveInArray, parseSeance, trimDecimalsIfNeeded, interleave, getPWADisplayMode, fnBrowserDetect, startTuto,
};
