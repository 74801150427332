import Vue from "vue";
import Vuex from "vuex";
import config from "@/config";
import Api from "@/modules/axios";
import * as Sentry from "@sentry/vue";
import avatarModule from "@/store.avatar";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => {
    const copy = { ...state };
    delete copy.avatar;
    return copy;
  },
});

export default new Vuex.Store({
  plugins: [vuexLocal.plugin],
  modules: {
    avatar: avatarModule,
  },
  state: {
    token: localStorage.getItem("token") || "",
    user: {},
    toastHandler: null,
    internetStatus: true,
    requestQueue: JSON.parse(localStorage.getItem("requestQueue") || "[]"),
    pwaDeferredPrompt: null,
    debug: false,
  },
  getters: {
    user(state) {
      return state.user;
    },
    token(state) {
      return state.token;
    },
    isAdmin(state) {
      return state.user && state.user.is_staff;
    },
    internetStatus(state) {
      return state.internetStatus;
    },
    nextRequestQueue(state) {
      return state.requestQueue[0];
    },
    isRequestQueueEmpty(state) {
      return state.requestQueue.length === 0;
    },
    pwaDeferredPrompt(state) {
      return state.pwaDeferredPrompt;
    },
    debug(state) {
      return state.debug;
    },
    isSmartWatch() {
      return false; // Désactivation smartwatch
      // const w = window.screen.width;
      // const h = window.screen.height;

      // const ratio = Math.abs(w / h);
      // return ratio > 0.8 && ratio < 1.2;
    },
  },
  mutations: {
    setUser(state, user) {
      // on ajoute le user dans state,
      state.user = user;
    },
    setToken(state, token) {
      state.token = token;
      if (token) {
        localStorage.setItem("token", token);
      } else {
        localStorage.removeItem("token");
      }
    },
    setToastHandler(state, handler) {
      state.toastHandler = handler;
    },
    setInternetStatus(state, val) {
      state.internetStatus = val;
    },
    appendRequestQueue(state, val) {
      state.requestQueue.push(val);
      localStorage.setItem("requestQueue", JSON.stringify(state.requestQueue));
    },
    removeRequestQueue(state) {
      state.requestQueue.splice(0, 1);
      localStorage.setItem("requestQueue", JSON.stringify(state.requestQueue));
    },
    setPwaDeferredPrompt(state, val) {
      state.pwaDeferredPrompt = val;
    },
    setDebug(state, val) {
      state.debug = val;
    },
  },
  actions: {
    displayToast(store, { text, isError, duration }) {
      store.state.toastHandler(text, isError, duration);
    },
    verifyToken(store) {
      return Api({ noHeader: true })
        .post("/verify_token/", { token: store.getters.token })
        .then((res) => {
          store.commit("setUser", res.data.user);
          Sentry.setUser({ username: res.data.user.username });
        });
    },
    login(store, credentials) {
      return Api({ noHeader: true })
        .post("/login", credentials)
        .then((res) => {
          store.commit("setUser", res.data.user);
          if (res.data.user) {
            Sentry.setUser({ username: res.data.user.username });
          }
          store.commit("setToken", res.data.token);
          return res.data.user && res.data.user.id;
        });
    },
    logout(store) {
      return fetch(`${config.api}/logout/`, {
        method: "POST",
        headers: {
          Authorization: `Token ${store.getters.token}`,
        },
      })
        .then(() => {
          store.commit("setUser", null);
          store.commit("setToken", null);
        });
    },
    checkOnlineStatus() {
      return Api().get(`${config.baseUrl}/checkingInternet`);
    },
    setInternetStatus(store, online) {
      store.commit("setInternetStatus", online);
      if (online && !store.getters.isRequestQueueEmpty) {
        store.dispatch("tryRequests");
      }
    },
    storeRequest(store, data) {
      store.commit("appendRequestQueue", data);
    },
    async tryRequests(store) {
      let nextReq = store.getters.nextRequestQueue;
      while (nextReq && nextReq.url) {
        // eslint-disable-next-line
        await Api().request({
          method: nextReq.method,
          url: nextReq.url,
          data: nextReq.data,
        })
          .then(() => {
            store.commit("removeRequestQueue");
          });

        nextReq = store.getters.nextRequestQueue;
      }
    },
  },
});
