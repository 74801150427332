<template>
  <div
    id="avatar_container"
    v-show="avatarReady && avatarShow"
    @click="avatarTextShow = !avatarTextShow"
  >
    <div
      v-if="avatarText && avatarTextShow"
      class="speak-text"
    >
      <div v-html="avatarText" />
    </div>
    <div
      id="avatar_zone"
      :class="`${currentExpression}-avatar`"
      :style="{
        borderColor: this.avatarText && !this.avatarTextShow ? 'rgba(161, 27, 34, 0.5)' : null,
      }"
    >
      <div
        v-if="currentExpression === 'thinking'"
        class="thinking"
      >
        ?
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CoachBot",
  computed: {
    ...mapGetters(["currentExpression", "avatarShow", "avatarText", "avatarInstance", "avatarReady"]),
  },
  data() {
    return {
      app: null,
      avatarTextShow: false,
    };
  },
  watch: {
    avatarShow() {
      if (this.avatarShow) {
        this.$nextTick(() => {
          this.app.viewer.resize();
        });
      }
    },
    avatarText() {
      if (!this.avatarTextShow) {
        this.avatarTextShow = true;
        if (this.$route.name !== "accueil") {
          setTimeout(() => {
            this.avatarTextShow = false;
          }, 5000);
        }
      }
    },
  },
  mounted() {
    import("@/modules/avatar/src/app").then((res) => {
      const Avatar = res.default;
      this.app = new Avatar(document.body, window.location);
      this.app.viewer.resize();
      this.$store.commit("setAvatarInstance", this.app);
    });
  },
};
</script>

<style lang="scss">
@use "@/assets/variables.scss" as var;

#avatar_container {
  position: fixed;
  bottom: 0.5rem;
  right: 0.5rem;
  display: flex;
  z-index: 10;
  justify-content: flex-end;
  align-items: center;
  .v-spinner {
    position: absolute;
    margin-bottom: 3rem;
    margin-right: 3rem;
  }
}

.speak-text {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: calc(100vw - 8rem);
  & > div {
    margin: 1rem;
    padding: 1rem;
    background: white;
    border-radius: var.$border-radius;
    position: relative;
    text-align: center;
    font-size: 0.8rem;
    // box-shadow: 0px 2px 5px 2px #000000;
    border: 1px solid var.$transparent-dark;
    &::after{
      content: '';
      position: absolute;
      right: -0.7rem;
      top: calc(50% - 10px);

      display : inline-block;
      height : 0;
      width : 0;
      border-top : 10px solid transparent;
      border-bottom : 10px solid transparent;
      border-left : 10px solid white;
    }
    &::before{
      content: '';
      position: absolute;
      right: -0.8rem;
      top: calc(50% - 12px);
      z-index: -1;

      display : inline-block;
      height : 0;
      width : 0;
      border-top : 12px solid transparent;
      border-bottom : 12px solid transparent;
      border-left : 12px solid var.$transparent-dark;
    }
  }
}

#avatar_zone {
  position: relative;
  width: 6rem;
  height: 6rem;
  align-self: flex-end;
  .thinking {
    position: absolute;
    font-size: 1.5rem;
    font-weight: bold;
    left: 0.7rem;
    top: 1rem;
  }
  border-radius: 50%;
  border: 1px solid var.$transparent-dark;
  overflow: hidden;
  background: linear-gradient(180deg, rgba(white, 0.70) 0%, white 50%);
}

// .div_avatar_container {
//   background: var.$red;
// }
</style>
