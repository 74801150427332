<template>
  <Modal
    :title="step.exercice.nom"
    @ask-close="$emit('close', )"
    @open="fetchCommentaire"
    @close="saveCommentaire"
    class="modal-exo"
  >
    <div class="modal-content">
      <div class="personal-comment">
        <FloatingInput
          label="Commentaire personnel"
          v-model="exoCom.commentaire"
          placeholder="Commentaire personnel: réglages de la machine, type de barre, type de prise..."
          @change="saved = false; edited = true;"
          type="textarea"
        />
      </div>
      <div
        v-if="edited"
        class="button-container"
      >
        <button
          :class="{success: edited && saved, }"
          @click="saveCommentaire"
        >
          <template v-if="edited && saved">
            Enregistré <i class="fa fa-check" />
          </template>
          <template v-else>
            Enregistrer
          </template>
        </button>
      </div>
      <div
        v-if="step.exercice.infos || step.exercice.img1 || step.exercice.img2 || step.exercice.img3"
        class="info-supp"
      >
        <label>Informations&nbsp;:</label>
        <div v-html="step.exercice.infos" />
        <img
          v-for="a in 4"
          :src="step.exercice[`img${a}`]"
          :key="a"
        />
      </div>
      <div
        v-if="Object.keys(selection, ).length"
        class="body-parts"
      >
        <label>Muscles sollicités&nbsp;:</label>
        <VueBodyPartSelector
          v-model="selection"
          class="human-body"
          disabled
        />
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import Api from "@/modules/axios";
import VueBodyPartSelector from "@/components/VueBodyPartSelector.vue";
import FloatingInput from "@/components/FloatingInput.vue";

export default {
  name: "ModalExoEntrainement",
  props: {
    step: Object,
  },
  components: {
    Modal,
    VueBodyPartSelector,
    FloatingInput,
  },
  data() {
    return {
      exoCom: {},
      selection: {},
      saved: false,
      edited: false,
    };
  },
  mounted() {
    if (this.step.exercice.muscles_1) {
      this.step.exercice.muscles_1.split(",").forEach((m) => {
        this.selection[m] = "primary";
      });
    }

    if (this.step.exercice.muscles_2) {
      this.step.exercice.muscles_2.split(",").forEach((m) => {
        this.selection[m] = "secondary";
      });
    }
  },
  beforeDestroy() {

  },
  methods: {
    fetchCommentaire() {
      Api().get(`/commentaire_exercice/?exercice=${this.step.exercice.id}`)
        .then((res) => {
          this.exoCom = res.data.length > 0 ? res.data[0] : { exercice: this.step.exercice.id };
        });
    },
    saveCommentaire() {
      let promise = null;

      if (this.exoCom.id) {
        promise = Api().patch(`/commentaire_exercice/${this.exoCom.id}/`, this.exoCom);
      } else {
        promise = Api().post("/commentaire_exercice/", this.exoCom);
      }
      promise
        .then((res) => {
          this.exoCom = res.data;
          this.saved = true;
        });
    },
  },
};
</script>

<style lang="scss">
.modal-img-container {
  height: 50%;
  overflow: hidden;
  margin: 0 -1rem;
  position: relative;
  border-top: 2px solid white;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
  }
}

.modal-exo {
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  img {
    margin-top: 1rem;
  }
}

.personal-comment {
  textarea {
    height: 8rem;
  }
  & + .button-container {
    margin: 1rem 0 2rem 0;
  }
}
.info-supp {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  width: 100%;

  textarea {
    height: 100%;
    resize: vertical;
  }
}

.body-parts {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}
.human-body object svg {
  height: 100%;
}

</style>
