/* eslint-disable no-console */

import { register } from "register-service-worker";
import Api from "@/modules/axios";

const PUBLIC_KEY = "BAWz53INlLyOJENM4XK7JExWH3f1MOJACpYTSg4lkmFadKRpAIqHEVeD6JqBTjRHHQTjfoXFDYfe7eB7ogsDiyU";

// // eslint-disable-next-line no-constant-condition

async function sendSubData(subscription) {
  const browser = navigator.userAgent.match(/(firefox|msie|chrome|safari|trident)/ig)[0].toLowerCase();
  const data = {
    status_type: "subscribe",
    subscription: subscription.toJSON(),
    browser,
  };

  return Api().post("/webpush/save_information", data)
    .then(() => ({
      valid: true,
      message: "Enregistrement réalisé avec succès.",
    }))
    .catch(() => ({
      valid: false,
      message: "Une erreur est survenue lors de l'enregistrement.",
    }));
}

function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  return outputArray.map((output, index) => rawData.charCodeAt(index));
}

async function subscribe(reg) {
  const subscription = await reg.pushManager.getSubscription();
  if (subscription) {
    return sendSubData(subscription);
  }

  const sub = await reg.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(PUBLIC_KEY),
  });
  return sendSubData(sub);
}

async function initState(reg) {
  let result;
  if (!reg.showNotification) {
    result = {
      valid: false,
      message: "Votre navigateur ne supporte pas l'affichage des notifications.",
    };
  } else if (Notification.permission === "denied") {
    result = {
      valid: false,
      message: "Vous n'avez pas autorisé la réception des notifications.",
    };
  } else if (!("PushManager" in window)) {
    result = {
      valid: false,
      message: "Votre navigateur n'autorise pas les notifications.",
    };
  }

  if (result != null) {
    return result;
  }

  return subscribe(reg);
}

async function registerServiceWorker() {
  let result;
  if ("serviceWorker" in navigator && Notification) {
    await Notification.requestPermission();

    await register(`${process.env.BASE_URL}service-worker.js`, {
      registered(registration) {
        console.log("Service worker has been registered.");
        initState(registration);

        document.dispatchEvent(
          new CustomEvent("setRegistration", { detail: registration }),
        );
      },
      cached() {
        console.log("Content has been cached for offline use.");
      },
      updatefound() {
        console.log("New content is downloading.");
      },
      updated(registration) {
        console.log("New content is available; please refresh.");
        document.dispatchEvent(
          new CustomEvent("swUpdated", { detail: registration }),
        );
      },
      offline() {
        console.log("No internet connection found. App is running in offline mode.");
      },
      error(error) {
        console.log(error);
        alert(`Error during service worker registration: ${JSON.stringify(error)}`);
      },
    });
  } else {
    result = {
      valid: false,
      message: "Vous ne pouvez pas recevoir de notifications push.",
    };
  }

  return result;
}

registerServiceWorker();
