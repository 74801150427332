<template>
  <div>
    <h2>Reprendre la séance en cours ?</h2>
    <ul>
      <li>
        <strong>Séance&nbsp;:</strong> {{ mountedSaveSeance.currentSeance.nom }}
      </li>
      <li v-if="stepsFlatten[mountedSaveSeance.currentStepIndex]">
        <strong>Exercice en cours&nbsp;:</strong>
        <template
          v-if="
            stepsFlatten[mountedSaveSeance.currentStepIndex].type === 'warmup'
          "
        >
          Échauffement
        </template>
        <template v-else>
          {{ stepsFlatten[mountedSaveSeance.currentStepIndex].exercice.nom }}
        </template>
      </li>
    </ul>
    <div class="button-container">
      <button @click="$emit('resume', )">Reprendre</button>
    </div>
    <ValidationButton
      text="Abandonner"
      secondary
      @validated="$emit('giveup', )"
    />
  </div>
</template>

<script>
import ValidationButton from "@/components/ValidationButton.vue";

export default {
  name: "ResumeEntrainement",
  props: {
    mountedSaveSeance: Object,
  },
  components: {
    ValidationButton,
  },
  computed: {
    stepsFlatten() {
      return this.mountedSaveSeance.currentSeance.steps.map((s) => {
        if (s.type === "exo") {
          return s.steps;
        } else if (s.type === "superset") {
          return s.steps.map((s2) => s2.steps).flat();
        } else {
          return s.step;
        }
      }).flat();
    },
  },
};
</script>

<style>

</style>
