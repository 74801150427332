<template>
  <div
    class="floating-label"
    :class="{border: bordered, }"
  >
    <textarea
      v-if="type === 'textarea'"
      :placeholder="realPlaceholder"
      :type="type"
      :disabled="disabled"
      v-model="vmodel"
    />
    <div
      v-else-if="type === 'file' && value && value.includes && value.includes('http://', )"
      class="file-placeholder"
    >
      <a
        :href="value"
        target="_blank"
      >
      {{ value.split("/", ).pop() }}
      </a>
      <i
        class="suppr fa fa-times"
        @click="$emit('delete-file', )"
      />
    </div>
    <div
      v-else-if="type === 'file' && value && value.name"
      class="file-placeholder"
    >
      {{ value.name }}
      <i
        class="suppr fa fa-times"
        @click="$emit('delete-file', )"
      />
    </div>
    <input
      v-else
      :placeholder="realPlaceholder"
      :value="value"
      :disabled="disabled"
      :type="type"
      @change="test"
      />
      <!-- @change="$emit('change', $event.target.value , )" -->
    <!-- The label -->
    <label>{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: "FloatingInput",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    label: String,
    value: [String, Number, File],
    disabled: Boolean,
    bordered: {
      type: Boolean,
      require: false,
      default: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      default: "text",
    },
  },
  computed: {
    realPlaceholder() {
      return this.placeholder || this.label;
    },
    vmodel: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("change", val);
      },
    },
  },
  methods: {
    test(event) {
      console.log(event);
      if (this.type === "file") {
        const files = event.target.files || event.dataTransfer.files;
        if (!files.length) {
          return;
        }

        console.log(files[0]);
        this.$emit("change", files[0]);
      } else {
        this.$emit("change", event.target.value);
      }
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/variables.scss" as var;

.floating-label {
  border-bottom: 1px solid var.$border-gray;
  border-radius: 0rem;
  position: relative;
  margin: 1rem 0;
  &.border {
    border: 1px solid var.$border-gray;
    &, input, textarea {
      border-radius: 0.5rem;
    }
    label {
      left: 0.5rem !important;
      padding: 0 0.5rem;
    }
  }
  label {
    /* Position the label */
    left: 1rem;
    position: absolute;
    top: 0;

    /* Hide it by default */
    opacity: 0;
    transition: all 200ms;

    font-weight: bold;
  }
  input, textarea, .file-placeholder {
    border: none;
    padding: 1rem;
    height: 100%;
    width: 100%;
    border: none;

    /*
    Show the label at desired position when the
    placeholder of input isn't shown
    */
    &:not(:placeholder-shown) + label {
      background: #fff;
      // transform: translate(-10%, -320%);
      top: -0.5rem;
      left: 0;
      opacity: 1;
    }
  }
}
</style>
